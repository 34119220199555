const CitiesData = [
    {
        "State": "California",
        "City": "Los Angeles",
        "StateID": "CA",
        "url": "losangeles-ca"
    },
    {
        "State": "California",
        "City": "San Diego",
        "StateID": "CA",
        "url": "sandiego-ca"
    },
    {
        "State": "California",
        "City": "San Jose",
        "StateID": "CA",
        "url": "sanjose-ca"
    },
    {
        "State": "California",
        "City": "San Francisco",
        "StateID": "CA",
        "url": "sanfrancisco-ca"
    },
    {
        "State": "California",
        "City": "Fresno",
        "StateID": "CA",
        "url": "fresno-ca"
    },
    {
        "State": "California",
        "City": "Sacramento",
        "StateID": "CA",
        "url": "sacramento-ca"
    },
    {
        "State": "California",
        "City": "Long Beach",
        "StateID": "CA",
        "url": "longbeach-ca"
    },
    {
        "State": "California",
        "City": "Oakland",
        "StateID": "CA",
        "url": "oakland-ca"
    },
    {
        "State": "California",
        "City": "Bakersfield",
        "StateID": "CA",
        "url": "bakersfield-ca"
    },
    {
        "State": "California",
        "City": "Anaheim",
        "StateID": "CA",
        "url": "anaheim-ca"
    },
    {
        "State": "California",
        "City": "Santa Ana",
        "StateID": "CA",
        "url": "santaana-ca"
    },
    {
        "State": "California",
        "City": "Riverside",
        "StateID": "CA",
        "url": "riverside-ca"
    },
    {
        "State": "California",
        "City": "Stockton",
        "StateID": "CA",
        "url": "stockton-ca"
    },
    {
        "State": "California",
        "City": "Chula Vista",
        "StateID": "CA",
        "url": "chulavista-ca"
    },
    {
        "State": "California",
        "City": "Irvine",
        "StateID": "CA",
        "url": "irvine-ca"
    },
    {
        "State": "California",
        "City": "Fremont",
        "StateID": "CA",
        "url": "fremont-ca"
    },
    {
        "State": "California",
        "City": "San Bernardino",
        "StateID": "CA",
        "url": "sanbernardino-ca"
    },
    {
        "State": "California",
        "City": "Modesto",
        "StateID": "CA",
        "url": "modesto-ca"
    },
    {
        "State": "California",
        "City": "Fontana",
        "StateID": "CA",
        "url": "fontana-ca"
    },
    {
        "State": "California",
        "City": "Oxnard",
        "StateID": "CA",
        "url": "oxnard-ca"
    },
    {
        "State": "California",
        "City": "Moreno Valley",
        "StateID": "CA",
        "url": "morenovalley-ca"
    },
    {
        "State": "California",
        "City": "Huntington Beach",
        "StateID": "CA",
        "url": "huntingtonbeach-ca"
    },
    {
        "State": "California",
        "City": "Glendale",
        "StateID": "CA",
        "url": "glendale-ca"
    },
    {
        "State": "California",
        "City": "Santa Clarita",
        "StateID": "CA",
        "url": "santaclarita-ca"
    },
    {
        "State": "California",
        "City": "Garden Grove",
        "StateID": "CA",
        "url": "gardengrove-ca"
    },
    {
        "State": "California",
        "City": "Oceanside",
        "StateID": "CA",
        "url": "oceanside-ca"
    },
    {
        "State": "California",
        "City": "Rancho Cucamonga",
        "StateID": "CA",
        "url": "ranchocucamonga-ca"
    },
    {
        "State": "California",
        "City": "Santa Rosa",
        "StateID": "CA",
        "url": "santarosa-ca"
    },
    {
        "State": "California",
        "City": "Ontario",
        "StateID": "CA",
        "url": "ontario-ca"
    },
    {
        "State": "California",
        "City": "Elk Grove",
        "StateID": "CA",
        "url": "elkgrove-ca"
    },
    {
        "State": "California",
        "City": "Corona",
        "StateID": "CA",
        "url": "corona-ca"
    },
    {
        "State": "California",
        "City": "Lancaster",
        "StateID": "CA",
        "url": "lancaster-ca"
    },
    {
        "State": "California",
        "City": "Palmdale",
        "StateID": "CA",
        "url": "palmdale-ca"
    },
    {
        "State": "California",
        "City": "Hayward",
        "StateID": "CA",
        "url": "hayward-ca"
    },
    {
        "State": "California",
        "City": "Salinas",
        "StateID": "CA",
        "url": "salinas-ca"
    },
    {
        "State": "California",
        "City": "Pomona",
        "StateID": "CA",
        "url": "pomona-ca"
    },
    {
        "State": "California",
        "City": "Escondido",
        "StateID": "CA",
        "url": "escondido-ca"
    },
    {
        "State": "California",
        "City": "Sunnyvale",
        "StateID": "CA",
        "url": "sunnyvale-ca"
    },
    {
        "State": "California",
        "City": "Torrance",
        "StateID": "CA",
        "url": "torrance-ca"
    },
    {
        "State": "California",
        "City": "Orange",
        "StateID": "CA",
        "url": "orange-ca"
    },
    {
        "State": "California",
        "City": "Pasadena",
        "StateID": "CA",
        "url": "pasadena-ca"
    },
    {
        "State": "California",
        "City": "Fullerton",
        "StateID": "CA",
        "url": "fullerton-ca"
    },
    {
        "State": "California",
        "City": "Thousand Oaks",
        "StateID": "CA",
        "url": "thousandoaks-ca"
    },
    {
        "State": "California",
        "City": "Visalia",
        "StateID": "CA",
        "url": "visalia-ca"
    },
    {
        "State": "California",
        "City": "Simi Valley",
        "StateID": "CA",
        "url": "simivalley-ca"
    },
    {
        "State": "California",
        "City": "Concord",
        "StateID": "CA",
        "url": "concord-ca"
    },
    {
        "State": "California",
        "City": "Roseville",
        "StateID": "CA",
        "url": "roseville-ca"
    },
    {
        "State": "California",
        "City": "Victorville",
        "StateID": "CA",
        "url": "victorville-ca"
    },
    {
        "State": "California",
        "City": "Santa Clara",
        "StateID": "CA",
        "url": "santaclara-ca"
    },
    {
        "State": "California",
        "City": "Vallejo",
        "StateID": "CA",
        "url": "vallejo-ca"
    },
    {
        "State": "California",
        "City": "Berkeley",
        "StateID": "CA",
        "url": "berkeley-ca"
    },
    {
        "State": "California",
        "City": "El Monte",
        "StateID": "CA",
        "url": "elmonte-ca"
    },
    {
        "State": "California",
        "City": "Downey",
        "StateID": "CA",
        "url": "downey-ca"
    },
    {
        "State": "California",
        "City": "Carlsbad",
        "StateID": "CA",
        "url": "carlsbad-ca"
    },
    {
        "State": "California",
        "City": "Costa Mesa",
        "StateID": "CA",
        "url": "costamesa-ca"
    },
    {
        "State": "California",
        "City": "Fairfield",
        "StateID": "CA",
        "url": "fairfield-ca"
    },
    {
        "State": "California",
        "City": "Temecula",
        "StateID": "CA",
        "url": "temecula-ca"
    },
    {
        "State": "California",
        "City": "Inglewood",
        "StateID": "CA",
        "url": "inglewood-ca"
    },
    {
        "State": "California",
        "City": "Ventura",
        "StateID": "CA",
        "url": "ventura-ca"
    },
    {
        "State": "California",
        "City": "West Covina",
        "StateID": "CA",
        "url": "westcovina-ca"
    },
    {
        "State": "California",
        "City": "Murrieta",
        "StateID": "CA",
        "url": "murrieta-ca"
    },
    {
        "State": "California",
        "City": "Richmond",
        "StateID": "CA",
        "url": "richmond-ca"
    },
    {
        "State": "California",
        "City": "Norwalk",
        "StateID": "CA",
        "url": "norwalk-ca"
    },
    {
        "State": "California",
        "City": "Antioch",
        "StateID": "CA",
        "url": "antioch-ca"
    },
    {
        "State": "California",
        "City": "Daly City",
        "StateID": "CA",
        "url": "dalycity-ca"
    },
    {
        "State": "California",
        "City": "Burbank",
        "StateID": "CA",
        "url": "burbank-ca"
    },
    {
        "State": "California",
        "City": "Santa Maria",
        "StateID": "CA",
        "url": "santamaria-ca"
    },
    {
        "State": "California",
        "City": "Clovis",
        "StateID": "CA",
        "url": "clovis-ca"
    },
    {
        "State": "California",
        "City": "Rialto",
        "StateID": "CA",
        "url": "rialto-ca"
    },
    {
        "State": "California",
        "City": "El Cajon",
        "StateID": "CA",
        "url": "elcajon-ca"
    },
    {
        "State": "California",
        "City": "San Mateo",
        "StateID": "CA",
        "url": "sanmateo-ca"
    },
    {
        "State": "California",
        "City": "Compton",
        "StateID": "CA",
        "url": "compton-ca"
    },
    {
        "State": "California",
        "City": "Vista",
        "StateID": "CA",
        "url": "vista-ca"
    },
    {
        "State": "California",
        "City": "Mission Viejo",
        "StateID": "CA",
        "url": "missionviejo-ca"
    },
    {
        "State": "California",
        "City": "Vacaville",
        "StateID": "CA",
        "url": "vacaville-ca"
    },
    {
        "State": "California",
        "City": "South Gate",
        "StateID": "CA",
        "url": "southgate-ca"
    },
    {
        "State": "California",
        "City": "Hesperia",
        "StateID": "CA",
        "url": "hesperia-ca"
    },
    {
        "State": "California",
        "City": "Carson",
        "StateID": "CA",
        "url": "carson-ca"
    },
    {
        "State": "California",
        "City": "Santa Monica",
        "StateID": "CA",
        "url": "santamonica-ca"
    },
    {
        "State": "California",
        "City": "Westminster",
        "StateID": "CA",
        "url": "westminster-ca"
    },
    {
        "State": "California",
        "City": "Redding",
        "StateID": "CA",
        "url": "redding-ca"
    },
    {
        "State": "California",
        "City": "Santa Barbara",
        "StateID": "CA",
        "url": "santabarbara-ca"
    },
    {
        "State": "California",
        "City": "San Leandro",
        "StateID": "CA",
        "url": "sanleandro-ca"
    },
    {
        "State": "California",
        "City": "Chico",
        "StateID": "CA",
        "url": "chico-ca"
    },
    {
        "State": "California",
        "City": "Hawthorne",
        "StateID": "CA",
        "url": "hawthorne-ca"
    },
    {
        "State": "California",
        "City": "Livermore",
        "StateID": "CA",
        "url": "livermore-ca"
    },
    {
        "State": "California",
        "City": "Tracy",
        "StateID": "CA",
        "url": "tracy-ca"
    },
    {
        "State": "California",
        "City": "Newport Beach",
        "StateID": "CA",
        "url": "newportbeach-ca"
    },
    {
        "State": "California",
        "City": "Whittier",
        "StateID": "CA",
        "url": "whittier-ca"
    },
    {
        "State": "California",
        "City": "Citrus Heights",
        "StateID": "CA",
        "url": "citrusheights-ca"
    },
    {
        "State": "California",
        "City": "Alhambra",
        "StateID": "CA",
        "url": "alhambra-ca"
    },
    {
        "State": "California",
        "City": "Menifee",
        "StateID": "CA",
        "url": "menifee-ca"
    },
    {
        "State": "California",
        "City": "Indio",
        "StateID": "CA",
        "url": "indio-ca"
    },
    {
        "State": "California",
        "City": "Buena Park",
        "StateID": "CA",
        "url": "buenapark-ca"
    },
    {
        "State": "California",
        "City": "Redwood City",
        "StateID": "CA",
        "url": "redwoodcity-ca"
    },
    {
        "State": "California",
        "City": "Chino",
        "StateID": "CA",
        "url": "chino-ca"
    },
    {
        "State": "California",
        "City": "Lakewood",
        "StateID": "CA",
        "url": "lakewood-ca"
    },
    {
        "State": "California",
        "City": "Tustin",
        "StateID": "CA",
        "url": "tustin-ca"
    },
    {
        "State": "California",
        "City": "Baldwin Park",
        "StateID": "CA",
        "url": "baldwinpark-ca"
    },
    {
        "State": "California",
        "City": "Perris",
        "StateID": "CA",
        "url": "perris-ca"
    },
    {
        "State": "California",
        "City": "Mountain View",
        "StateID": "CA",
        "url": "mountainview-ca"
    },
    {
        "State": "California",
        "City": "Lynwood",
        "StateID": "CA",
        "url": "lynwood-ca"
    },
    {
        "State": "California",
        "City": "Folsom",
        "StateID": "CA",
        "url": "folsom-ca"
    },
    {
        "State": "California",
        "City": "Napa",
        "StateID": "CA",
        "url": "napa-ca"
    },
    {
        "State": "California",
        "City": "San Ramon",
        "StateID": "CA",
        "url": "sanramon-ca"
    },
    {
        "State": "California",
        "City": "Pleasanton",
        "StateID": "CA",
        "url": "pleasanton-ca"
    },
    {
        "State": "California",
        "City": "Union City",
        "StateID": "CA",
        "url": "unioncity-ca"
    },
    {
        "State": "California",
        "City": "Hemet",
        "StateID": "CA",
        "url": "hemet-ca"
    },
    {
        "State": "California",
        "City": "Merced",
        "StateID": "CA",
        "url": "merced-ca"
    },
    {
        "State": "California",
        "City": "Chino Hills",
        "StateID": "CA",
        "url": "chinohills-ca"
    },
    {
        "State": "California",
        "City": "Apple Valley",
        "StateID": "CA",
        "url": "applevalley-ca"
    },
    {
        "State": "California",
        "City": "Redwood City",
        "StateID": "CA",
        "url": "redwoodcity-ca"
    },
    {
        "State": "California",
        "City": "Turlock",
        "StateID": "CA",
        "url": "turlock-ca"
    },
    {
        "State": "California",
        "City": "Manteca",
        "StateID": "CA",
        "url": "manteca-ca"
    },
    {
        "State": "California",
        "City": "Bellflower",
        "StateID": "CA",
        "url": "bellflower-ca"
    },
    {
        "State": "California",
        "City": "Alameda",
        "StateID": "CA",
        "url": "alameda-ca"
    },
    {
        "State": "California",
        "City": "Redlands",
        "StateID": "CA",
        "url": "redlands-ca"
    },
    {
        "State": "California",
        "City": "Milpitas",
        "StateID": "CA",
        "url": "milpitas-ca"
    },
    {
        "State": "California",
        "City": "Pittsburg",
        "StateID": "CA",
        "url": "pittsburg-ca"
    },
    {
        "State": "California",
        "City": "Rancho Cordova",
        "StateID": "CA",
        "url": "ranchocordova-ca"
    },
    {
        "State": "California",
        "City": "Rocklin",
        "StateID": "CA",
        "url": "rocklin-ca"
    },
    {
        "State": "California",
        "City": "Yuba City",
        "StateID": "CA",
        "url": "yubacity-ca"
    },
    {
        "State": "California",
        "City": "Yucaipa",
        "StateID": "CA",
        "url": "yucaipa-ca"
    },
    {
        "State": "California",
        "City": "Petaluma",
        "StateID": "CA",
        "url": "petaluma-ca"
    },
    {
        "State": "California",
        "City": "Camarillo",
        "StateID": "CA",
        "url": "camarillo-ca"
    },
    {
        "State": "California",
        "City": "Lodi",
        "StateID": "CA",
        "url": "lodi-ca"
    },
    {
        "State": "California",
        "City": "Santee",
        "StateID": "CA",
        "url": "santee-ca"
    },
    {
        "State": "California",
        "City": "Arcadia",
        "StateID": "CA",
        "url": "arcadia-ca"
    },
    {
        "State": "California",
        "City": "Diamond Bar",
        "StateID": "CA",
        "url": "diamondbar-ca"
    },
    {
        "State": "California",
        "City": "Woodland",
        "StateID": "CA",
        "url": "woodland-ca"
    },
    {
        "State": "California",
        "City": "National City",
        "StateID": "CA",
        "url": "nationalcity-ca"
    },
    {
        "State": "California",
        "City": "San Marcos",
        "StateID": "CA",
        "url": "sanmarcos-ca"
    },
    {
        "State": "California",
        "City": "Poway",
        "StateID": "CA",
        "url": "poway-ca"
    },
    {
        "State": "California",
        "City": "Cupertino",
        "StateID": "CA",
        "url": "cupertino-ca"
    },
    {
        "State": "California",
        "City": "Azusa",
        "StateID": "CA",
        "url": "azusa-ca"
    },
    {
        "State": "California",
        "City": "Lake Elsinore",
        "StateID": "CA",
        "url": "lakeelsinore-ca"
    },
    {
        "State": "California",
        "City": "La Habra",
        "StateID": "CA",
        "url": "lahabra-ca"
    },
    {
        "State": "California",
        "City": "Rancho Santa Margarita",
        "StateID": "CA",
        "url": "ranchosantamargarita-ca"
    },
    {
        "State": "California",
        "City": "Monterey Park",
        "StateID": "CA",
        "url": "montereypark-ca"
    },
    {
        "State": "California",
        "City": "Cypress",
        "StateID": "CA",
        "url": "cypress-ca"
    },
    {
        "State": "California",
        "City": "La Mesa",
        "StateID": "CA",
        "url": "lamesa-ca"
    },
    {
        "State": "California",
        "City": "Lompoc",
        "StateID": "CA",
        "url": "lompoc-ca"
    },
    {
        "State": "California",
        "City": "Palm Springs",
        "StateID": "CA",
        "url": "palmsprings-ca"
    },
    {
        "State": "California",
        "City": "San Jacinto",
        "StateID": "CA",
        "url": "sanjacinto-ca"
    },
    {
        "State": "California",
        "City": "San Clemente",
        "StateID": "CA",
        "url": "sanclemente-ca"
    },
    {
        "State": "California",
        "City": "Ceres",
        "StateID": "CA",
        "url": "ceres-ca"
    },
    {
        "State": "California",
        "City": "Pico Rivera",
        "StateID": "CA",
        "url": "picorivera-ca"
    },
    {
        "State": "California",
        "City": "San Luis Obispo",
        "StateID": "CA",
        "url": "sanluisobispo-ca"
    },
    {
        "State": "California",
        "City": "Placentia",
        "StateID": "CA",
        "url": "placentia-ca"
    },
    {
        "State": "California",
        "City": "Highland",
        "StateID": "CA",
        "url": "highland-ca"
    },
    {
        "State": "California",
        "City": "La Puente",
        "StateID": "CA",
        "url": "lapuente-ca"
    },
    {
        "State": "California",
        "City": "Gardena",
        "StateID": "CA",
        "url": "gardena-ca"
    },
    {
        "State": "California",
        "City": "Stanton",
        "StateID": "CA",
        "url": "stanton-ca"
    },
    {
        "State": "California",
        "City": "La Mirada",
        "StateID": "CA",
        "url": "lamirada-ca"
    },
    {
        "State": "California",
        "City": "Lodi",
        "StateID": "CA",
        "url": "lodi-ca"
    },
    {
        "State": "California",
        "City": "Santee",
        "StateID": "CA",
        "url": "santee-ca"
    },
    {
        "State": "California",
        "City": "Arcadia",
        "StateID": "CA",
        "url": "arcadia-ca"
    },
    {
        "State": "California",
        "City": "Brentwood",
        "StateID": "CA",
        "url": "brentwood-ca"
    },
    {
        "State": "California",
        "City": "Baldwin Park",
        "StateID": "CA",
        "url": "baldwinpark-ca"
    },
    {
        "State": "California",
        "City": "Redondo Beach",
        "StateID": "CA",
        "url": "redondobeach-ca"
    },
    {
        "State": "California",
        "City": "Woodland",
        "StateID": "CA",
        "url": "woodland-ca"
    },
    {
        "State": "California",
        "City": "Eastvale",
        "StateID": "CA",
        "url": "eastvale-ca"
    },
    {
        "State": "California",
        "City": "Cerritos",
        "StateID": "CA",
        "url": "cerritos-ca"
    },
    {
        "State": "California",
        "City": "Aliso Viejo",
        "StateID": "CA",
        "url": "alisoviejo-ca"
    },
    {
        "State": "California",
        "City": "Bell Gardens",
        "StateID": "CA",
        "url": "bellgardens-ca"
    },
    {
        "State": "California",
        "City": "Mcoman Hill",
        "StateID": "CA",
        "url": "mcomanhill-ca"
    },
    {
        "State": "California",
        "City": "Lathrop",
        "StateID": "CA",
        "url": "lathrop-ca"
    },
    {
        "State": "California",
        "City": "Pacifica",
        "StateID": "CA",
        "url": "pacifica-ca"
    },
    {
        "State": "California",
        "City": "Reedley",
        "StateID": "CA",
        "url": "reedley-ca"
    },
    {
        "State": "California",
        "City": "Rosemead",
        "StateID": "CA",
        "url": "rosemead-ca"
    },
    {
        "State": "California",
        "City": "Saratoga",
        "StateID": "CA",
        "url": "saratoga-ca"
    },
    {
        "State": "California",
        "City": "Cerritos",
        "StateID": "CA",
        "url": "cerritos-ca"
    },
    {
        "State": "California",
        "City": "Brea",
        "StateID": "CA",
        "url": "brea-ca"
    },
    {
        "State": "California",
        "City": "Colton",
        "StateID": "CA",
        "url": "colton-ca"
    },
    {
        "State": "California",
        "City": "Palm Desert",
        "StateID": "CA",
        "url": "palmdesert-ca"
    },
    {
        "State": "California",
        "City": "Fountain Valley",
        "StateID": "CA",
        "url": "fountainvalley-ca"
    },
    {
        "State": "California",
        "City": "Upland",
        "StateID": "CA",
        "url": "upland-ca"
    },
    {
        "State": "California",
        "City": "Covina",
        "StateID": "CA",
        "url": "covina-ca"
    },
    {
        "State": "California",
        "City": "Gilroy",
        "StateID": "CA",
        "url": "gilroy-ca"
    },
    {
        "State": "California",
        "City": "Pleasant Hill",
        "StateID": "CA",
        "url": "pleasanthill-ca"
    },
    {
        "State": "California",
        "City": "San Bruno",
        "StateID": "CA",
        "url": "sanbruno-ca"
    },
    {
        "State": "California",
        "City": "Martinez",
        "StateID": "CA",
        "url": "martinez-ca"
    },
    {
        "State": "California",
        "City": "Lincoln",
        "StateID": "CA",
        "url": "lincoln-ca"
    },
    {
        "State": "California",
        "City": "Los Gatos",
        "StateID": "CA",
        "url": "losgatos-ca"
    },
    {
        "State": "California",
        "City": "Tulare",
        "StateID": "CA",
        "url": "tulare-ca"
    },
    {
        "State": "California",
        "City": "Cathedral City",
        "StateID": "CA",
        "url": "cathedralcity-ca"
    },
    {
        "State": "California",
        "City": "Cupertino",
        "StateID": "CA",
        "url": "cupertino-ca"
    },
    {
        "State": "California",
        "City": "Danville",
        "StateID": "CA",
        "url": "danville-ca"
    },
    {
        "State": "California",
        "City": "Lompoc",
        "StateID": "CA",
        "url": "lompoc-ca"
    },
    {
        "State": "California",
        "City": "Rohnert Park",
        "StateID": "CA",
        "url": "rohnertpark-ca"
    },
    {
        "State": "California",
        "City": "Novato",
        "StateID": "CA",
        "url": "novato-ca"
    },
    {
        "State": "California",
        "City": "Cupertino",
        "StateID": "CA",
        "url": "cupertino-ca"
    },
    {
        "State": "California",
        "City": "San Juan Capistrano",
        "StateID": "CA",
        "url": "sanjuancapistrano-ca"
    },
    {
        "State": "California",
        "City": "Glendora",
        "StateID": "CA",
        "url": "glendora-ca"
    },
    {
        "State": "California",
        "City": "Belmont",
        "StateID": "CA",
        "url": "belmont-ca"
    },
    {
        "State": "California",
        "City": "Milpitas",
        "StateID": "CA",
        "url": "milpitas-ca"
    },
    {
        "State": "California",
        "City": "Martinez",
        "StateID": "CA",
        "url": "martinez-ca"
    },
    {
        "State": "California",
        "City": "Woodland",
        "StateID": "CA",
        "url": "woodland-ca"
    },
    {
        "State": "California",
        "City": "Hanford",
        "StateID": "CA",
        "url": "hanford-ca"
    },
    {
        "State": "California",
        "City": "Encinitas",
        "StateID": "CA",
        "url": "encinitas-ca"
    },
    {
        "State": "California",
        "City": "Pacifica",
        "StateID": "CA",
        "url": "pacifica-ca"
    },
    {
        "State": "California",
        "City": "Cupertino",
        "StateID": "CA",
        "url": "cupertino-ca"
    },
    {
        "State": "California",
        "City": "Santa Paula",
        "StateID": "CA",
        "url": "santapaula-ca"
    },
    {
        "State": "California",
        "City": "Aliso Viejo",
        "StateID": "CA",
        "url": "alisoviejo-ca"
    },
    {
        "State": "California",
        "City": "El Centro",
        "StateID": "CA",
        "url": "elcentro-ca"
    },
    {
        "State": "California",
        "City": "Culver City",
        "StateID": "CA",
        "url": "culvercity-ca"
    },
    {
        "State": "California",
        "City": "South Lake Tahoe",
        "StateID": "CA",
        "url": "southlaketahoe-ca"
    },
    {
        "State": "California",
        "City": "Arroyo Grande",
        "StateID": "CA",
        "url": "arroyogrande-ca"
    },
    {
        "State": "California",
        "City": "Susanville",
        "StateID": "CA",
        "url": "susanville-ca"
    },
    {
        "State": "California",
        "City": "Sebastopol",
        "StateID": "CA",
        "url": "sebastopol-ca"
    },
    {
        "State": "California",
        "City": "Yucca Valley",
        "StateID": "CA",
        "url": "yuccavalley-ca"
    },
    {
        "State": "California",
        "City": "Lomita",
        "StateID": "CA",
        "url": "lomita-ca"
    },
    {
        "State": "California",
        "City": "Half Moon Bay",
        "StateID": "CA",
        "url": "halfmoonbay-ca"
    },
    {
        "State": "California",
        "City": "Paradise",
        "StateID": "CA",
        "url": "paradise-ca"
    },
    {
        "State": "California",
        "City": "Dixon",
        "StateID": "CA",
        "url": "dixon-ca"
    },
    {
        "State": "California",
        "City": "San Dimas",
        "StateID": "CA",
        "url": "sandimas-ca"
    },
    {
        "State": "California",
        "City": "Sonora",
        "StateID": "CA",
        "url": "sonora-ca"
    },
    {
        "State": "California",
        "City": "Wheatland",
        "StateID": "CA",
        "url": "wheatland-ca"
    },
    {
        "State": "California",
        "City": "Clayton",
        "StateID": "CA",
        "url": "clayton-ca"
    },
    {
        "State": "California",
        "City": "Fillmore",
        "StateID": "CA",
        "url": "fillmore-ca"
    },
    {
        "State": "California",
        "City": "Imperial",
        "StateID": "CA",
        "url": "imperial-ca"
    },
    {
        "State": "California",
        "City": "Marina",
        "StateID": "CA",
        "url": "marina-ca"
    },
    {
        "State": "California",
        "City": "La Verne",
        "StateID": "CA",
        "url": "laverne-ca"
    },
    {
        "State": "California",
        "City": "Los Alamitos",
        "StateID": "CA",
        "url": "losalamitos-ca"
    },
    {
        "State": "California",
        "City": "Hollister",
        "StateID": "CA",
        "url": "hollister-ca"
    },
    {
        "State": "California",
        "City": "Twentynine Palms",
        "StateID": "CA",
        "url": "twentyninepalms-ca"
    },
    {
        "State": "California",
        "City": "Calabasas",
        "StateID": "CA",
        "url": "calabasas-ca"
    },
    {
        "State": "California",
        "City": "Bell Gardens",
        "StateID": "CA",
        "url": "bellgardens-ca"
    },
    {
        "State": "California",
        "City": "Los Banos",
        "StateID": "CA",
        "url": "losbanos-ca"
    },
    {
        "State": "California",
        "City": "Colton",
        "StateID": "CA",
        "url": "colton-ca"
    },
    {
        "State": "California",
        "City": "San Gabriel",
        "StateID": "CA",
        "url": "sangabriel-ca"
    },
    {
        "State": "California",
        "City": "Foster City",
        "StateID": "CA",
        "url": "fostercity-ca"
    },
    {
        "State": "California",
        "City": "Morro Bay",
        "StateID": "CA",
        "url": "morrobay-ca"
    },
    {
        "State": "California",
        "City": "Pismo Beach",
        "StateID": "CA",
        "url": "pismobeach-ca"
    },
    {
        "State": "California",
        "City": "Lomita",
        "StateID": "CA",
        "url": "lomita-ca"
    },
    {
        "State": "California",
        "City": "Tehachapi",
        "StateID": "CA",
        "url": "tehachapi-ca"
    },
    {
        "State": "California",
        "City": "Orange Cove",
        "StateID": "CA",
        "url": "orangecove-ca"
    },
    {
        "State": "California",
        "City": "Manhattan Beach",
        "StateID": "CA",
        "url": "manhattanbeach-ca"
    },
    {
        "State": "California",
        "City": "Ridgecrest",
        "StateID": "CA",
        "url": "ridgecrest-ca"
    },
    {
        "State": "California",
        "City": "Seal Beach",
        "StateID": "CA",
        "url": "sealbeach-ca"
    },
    {
        "State": "California",
        "City": "Dinuba",
        "StateID": "CA",
        "url": "dinuba-ca"
    },
    {
        "State": "California",
        "City": "Orinda",
        "StateID": "CA",
        "url": "orinda-ca"
    },
    {
        "State": "California",
        "City": "Greenfield",
        "StateID": "CA",
        "url": "greenfield-ca"
    },
    {
        "State": "California",
        "City": "Arroyo Grande",
        "StateID": "CA",
        "url": "arroyogrande-ca"
    },
    {
        "State": "California",
        "City": "Wildomar",
        "StateID": "CA",
        "url": "wildomar-ca"
    },
    {
        "State": "California",
        "City": "Walnut",
        "StateID": "CA",
        "url": "walnut-ca"
    },
    {
        "State": "California",
        "City": "Lathrop",
        "StateID": "CA",
        "url": "lathrop-ca"
    },
    {
        "State": "California",
        "City": "Adelanto",
        "StateID": "CA",
        "url": "adelanto-ca"
    },
    {
        "State": "California",
        "City": "Grand Terrace",
        "StateID": "CA",
        "url": "grandterrace-ca"
    },
    {
        "State": "California",
        "City": "Beaumont",
        "StateID": "CA",
        "url": "beaumont-ca"
    },
    {
        "State": "Texas",
        "City": "Houston",
        "StateID": "TX",
        "url": "houston-tx"
    },
    {
        "State": "Texas",
        "City": "San Antonio",
        "StateID": "TX",
        "url": "sanantonio-tx"
    },
    {
        "State": "Texas",
        "City": "Dallas",
        "StateID": "TX",
        "url": "dallas-tx"
    },
    {
        "State": "Texas",
        "City": "Austin",
        "StateID": "TX",
        "url": "austin-tx"
    },
    {
        "State": "Texas",
        "City": "Fort Worth",
        "StateID": "TX",
        "url": "fortworth-tx"
    },
    {
        "State": "Texas",
        "City": "El Paso",
        "StateID": "TX",
        "url": "elpaso-tx"
    },
    {
        "State": "Texas",
        "City": "Arlington",
        "StateID": "TX",
        "url": "arlington-tx"
    },
    {
        "State": "Texas",
        "City": "Corpus Christi",
        "StateID": "TX",
        "url": "corpuschristi-tx"
    },
    {
        "State": "Texas",
        "City": "Plano",
        "StateID": "TX",
        "url": "plano-tx"
    },
    {
        "State": "Texas",
        "City": "Laredo",
        "StateID": "TX",
        "url": "laredo-tx"
    },
    {
        "State": "Texas",
        "City": "Lubbock",
        "StateID": "TX",
        "url": "lubbock-tx"
    },
    {
        "State": "Texas",
        "City": "Garland",
        "StateID": "TX",
        "url": "garland-tx"
    },
    {
        "State": "Texas",
        "City": "Irving",
        "StateID": "TX",
        "url": "irving-tx"
    },
    {
        "State": "Texas",
        "City": "Amarillo",
        "StateID": "TX",
        "url": "amarillo-tx"
    },
    {
        "State": "Texas",
        "City": "Grand Prairie",
        "StateID": "TX",
        "url": "grandprairie-tx"
    },
    {
        "State": "Texas",
        "City": "Brownsville",
        "StateID": "TX",
        "url": "brownsville-tx"
    },
    {
        "State": "Texas",
        "City": "Pasadena",
        "StateID": "TX",
        "url": "pasadena-tx"
    },
    {
        "State": "Texas",
        "City": "McKinney",
        "StateID": "TX",
        "url": "mckinney-tx"
    },
    {
        "State": "Texas",
        "City": "Mesquite",
        "StateID": "TX",
        "url": "mesquite-tx"
    },
    {
        "State": "Texas",
        "City": "Killeen",
        "StateID": "TX",
        "url": "killeen-tx"
    },
    {
        "State": "Texas",
        "City": "Frisco",
        "StateID": "TX",
        "url": "frisco-tx"
    },
    {
        "State": "Texas",
        "City": "McAllen",
        "StateID": "TX",
        "url": "mcallen-tx"
    },
    {
        "State": "Texas",
        "City": "Waco",
        "StateID": "TX",
        "url": "waco-tx"
    },
    {
        "State": "Texas",
        "City": "Carrollton",
        "StateID": "TX",
        "url": "carrollton-tx"
    },
    {
        "State": "Texas",
        "City": "Midland",
        "StateID": "TX",
        "url": "midland-tx"
    },
    {
        "State": "Texas",
        "City": "Denton",
        "StateID": "TX",
        "url": "denton-tx"
    },
    {
        "State": "Texas",
        "City": "Abilene",
        "StateID": "TX",
        "url": "abilene-tx"
    },
    {
        "State": "Texas",
        "City": "Beaumont",
        "StateID": "TX",
        "url": "beaumont-tx"
    },
    {
        "State": "Texas",
        "City": "Round Rock",
        "StateID": "TX",
        "url": "roundrock-tx"
    },
    {
        "State": "Texas",
        "City": "Odessa",
        "StateID": "TX",
        "url": "odessa-tx"
    },
    {
        "State": "Texas",
        "City": "Sugar Land",
        "StateID": "TX",
        "url": "sugarland-tx"
    },
    {
        "State": "Texas",
        "City": "College Station",
        "StateID": "TX",
        "url": "collegestation-tx"
    },
    {
        "State": "Texas",
        "City": "Lewisville",
        "StateID": "TX",
        "url": "lewisville-tx"
    },
    {
        "State": "Texas",
        "City": "Tyler",
        "StateID": "TX",
        "url": "tyler-tx"
    },
    {
        "State": "Texas",
        "City": "Pearland",
        "StateID": "TX",
        "url": "pearland-tx"
    },
    {
        "State": "Texas",
        "City": "San Angelo",
        "StateID": "TX",
        "url": "sanangelo-tx"
    },
    {
        "State": "Texas",
        "City": "Allen",
        "StateID": "TX",
        "url": "allen-tx"
    },
    {
        "State": "Texas",
        "City": "League City",
        "StateID": "TX",
        "url": "leaguecity-tx"
    },
    {
        "State": "Texas",
        "City": "Wichita Falls",
        "StateID": "TX",
        "url": "wichitafalls-tx"
    },
    {
        "State": "Texas",
        "City": "Edinburg",
        "StateID": "TX",
        "url": "edinburg-tx"
    },
    {
        "State": "Texas",
        "City": "Mission",
        "StateID": "TX",
        "url": "mission-tx"
    },
    {
        "State": "Texas",
        "City": "Bryan",
        "StateID": "TX",
        "url": "bryan-tx"
    },
    {
        "State": "Texas",
        "City": "Longview",
        "StateID": "TX",
        "url": "longview-tx"
    },
    {
        "State": "Texas",
        "City": "Pharr",
        "StateID": "TX",
        "url": "pharr-tx"
    },
    {
        "State": "Texas",
        "City": "Baytown",
        "StateID": "TX",
        "url": "baytown-tx"
    },
    {
        "State": "Texas",
        "City": "Missouri City",
        "StateID": "TX",
        "url": "missouricity-tx"
    },
    {
        "State": "Texas",
        "City": "Temple",
        "StateID": "TX",
        "url": "temple-tx"
    },
    {
        "State": "Texas",
        "City": "Flower Mound",
        "StateID": "TX",
        "url": "flowermound-tx"
    },
    {
        "State": "Texas",
        "City": "New Braunfels",
        "StateID": "TX",
        "url": "newbraunfels-tx"
    },
    {
        "State": "Texas",
        "City": "North Richland Hills",
        "StateID": "TX",
        "url": "northrichlandhills-tx"
    },
    {
        "State": "Texas",
        "City": "Conroe",
        "StateID": "TX",
        "url": "conroe-tx"
    },
    {
        "State": "Texas",
        "City": "Victoria",
        "StateID": "TX",
        "url": "victoria-tx"
    },
    {
        "State": "Texas",
        "City": "Cedar Park",
        "StateID": "TX",
        "url": "cedarpark-tx"
    },
    {
        "State": "Texas",
        "City": "Mansfield",
        "StateID": "TX",
        "url": "mansfield-tx"
    },
    {
        "State": "Texas",
        "City": "Rowlett",
        "StateID": "TX",
        "url": "rowlett-tx"
    },
    {
        "State": "Texas",
        "City": "Gecometown",
        "StateID": "TX",
        "url": "gecometown-tx"
    },
    {
        "State": "Texas",
        "City": "Pflugerville",
        "StateID": "TX",
        "url": "pflugerville-tx"
    },
    {
        "State": "Texas",
        "City": "Port Arthur",
        "StateID": "TX",
        "url": "portarthur-tx"
    },
    {
        "State": "Texas",
        "City": "Euless",
        "StateID": "TX",
        "url": "euless-tx"
    },
    {
        "State": "Texas",
        "City": "DeSoto",
        "StateID": "TX",
        "url": "desoto-tx"
    },
    {
        "State": "Texas",
        "City": "Grapevine",
        "StateID": "TX",
        "url": "grapevine-tx"
    },
    {
        "State": "Texas",
        "City": "Galveston",
        "StateID": "TX",
        "url": "galveston-tx"
    },
    {
        "State": "Texas",
        "City": "Bedford",
        "StateID": "TX",
        "url": "bedford-tx"
    },
    {
        "State": "Texas",
        "City": "Cedar Hill",
        "StateID": "TX",
        "url": "cedarhill-tx"
    },
    {
        "State": "Texas",
        "City": "Texas City",
        "StateID": "TX",
        "url": "texascity-tx"
    },
    {
        "State": "Texas",
        "City": "Wylie",
        "StateID": "TX",
        "url": "wylie-tx"
    },
    {
        "State": "Texas",
        "City": "Haltom City",
        "StateID": "TX",
        "url": "haltomcity-tx"
    },
    {
        "State": "Texas",
        "City": "Keller",
        "StateID": "TX",
        "url": "keller-tx"
    },
    {
        "State": "Texas",
        "City": "Coppell",
        "StateID": "TX",
        "url": "coppell-tx"
    },
    {
        "State": "Texas",
        "City": "Hurst",
        "StateID": "TX",
        "url": "hurst-tx"
    },
    {
        "State": "Texas",
        "City": "Burleson",
        "StateID": "TX",
        "url": "burleson-tx"
    },
    {
        "State": "Texas",
        "City": "Huntsville",
        "StateID": "TX",
        "url": "huntsville-tx"
    },
    {
        "State": "Texas",
        "City": "Schertz",
        "StateID": "TX",
        "url": "schertz-tx"
    },
    {
        "State": "Texas",
        "City": "Lancaster",
        "StateID": "TX",
        "url": "lancaster-tx"
    },
    {
        "State": "Texas",
        "City": "Channelview",
        "StateID": "TX",
        "url": "channelview-tx"
    },
    {
        "State": "Texas",
        "City": "Rosenberg",
        "StateID": "TX",
        "url": "rosenberg-tx"
    },
    {
        "State": "Texas",
        "City": "Rockwall",
        "StateID": "TX",
        "url": "rockwall-tx"
    },
    {
        "State": "Texas",
        "City": "Kyle",
        "StateID": "TX",
        "url": "kyle-tx"
    },
    {
        "State": "Texas",
        "City": "The Colony",
        "StateID": "TX",
        "url": "thecolony-tx"
    },
    {
        "State": "Texas",
        "City": "Farmers Branch",
        "StateID": "TX",
        "url": "farmersbranch-tx"
    },
    {
        "State": "Texas",
        "City": "Weslaco",
        "StateID": "TX",
        "url": "weslaco-tx"
    },
    {
        "State": "Texas",
        "City": "Leander",
        "StateID": "TX",
        "url": "leander-tx"
    },
    {
        "State": "Texas",
        "City": "Friendswood",
        "StateID": "TX",
        "url": "friendswood-tx"
    },
    {
        "State": "Texas",
        "City": "San Marcos",
        "StateID": "TX",
        "url": "sanmarcos-tx"
    },
    {
        "State": "Texas",
        "City": "Lufkin",
        "StateID": "TX",
        "url": "lufkin-tx"
    },
    {
        "State": "Texas",
        "City": "Sherman",
        "StateID": "TX",
        "url": "sherman-tx"
    },
    {
        "State": "Texas",
        "City": "Corsicana",
        "StateID": "TX",
        "url": "corsicana-tx"
    },
    {
        "State": "Texas",
        "City": "Canyon",
        "StateID": "TX",
        "url": "canyon-tx"
    },
    {
        "State": "Texas",
        "City": "Paris",
        "StateID": "TX",
        "url": "paris-tx"
    },
    {
        "State": "Texas",
        "City": "Marshall",
        "StateID": "TX",
        "url": "marshall-tx"
    },
    {
        "State": "Texas",
        "City": "Levelland",
        "StateID": "TX",
        "url": "levelland-tx"
    },
    {
        "State": "Texas",
        "City": "Alice",
        "StateID": "TX",
        "url": "alice-tx"
    },
    {
        "State": "Texas",
        "City": "Angleton",
        "StateID": "TX",
        "url": "angleton-tx"
    },
    {
        "State": "Texas",
        "City": "Balch Springs",
        "StateID": "TX",
        "url": "balchsprings-tx"
    },
    {
        "State": "Texas",
        "City": "Liberty",
        "StateID": "TX",
        "url": "liberty-tx"
    },
    {
        "State": "Texas",
        "City": "Graham",
        "StateID": "TX",
        "url": "graham-tx"
    },
    {
        "State": "Texas",
        "City": "Tomball",
        "StateID": "TX",
        "url": "tomball-tx"
    },
    {
        "State": "Texas",
        "City": "Harker Heights",
        "StateID": "TX",
        "url": "harkerheights-tx"
    },
    {
        "State": "Texas",
        "City": "Kermit",
        "StateID": "TX",
        "url": "kermit-tx"
    },
    {
        "State": "Texas",
        "City": "Seguin",
        "StateID": "TX",
        "url": "seguin-tx"
    },
    {
        "State": "Texas",
        "City": "Kingsville",
        "StateID": "TX",
        "url": "kingsville-tx"
    },
    {
        "State": "Texas",
        "City": "Kerrville",
        "StateID": "TX",
        "url": "kerrville-tx"
    },
    {
        "State": "Texas",
        "City": "Mineral Wells",
        "StateID": "TX",
        "url": "mineralwells-tx"
    },
    {
        "State": "Texas",
        "City": "Little Elm",
        "StateID": "TX",
        "url": "littleelm-tx"
    },
    {
        "State": "Texas",
        "City": "Cleburne",
        "StateID": "TX",
        "url": "cleburne-tx"
    },
    {
        "State": "Texas",
        "City": "Socorro",
        "StateID": "TX",
        "url": "socorro-tx"
    },
    {
        "State": "Texas",
        "City": "Alamo",
        "StateID": "TX",
        "url": "alamo-tx"
    },
    {
        "State": "Texas",
        "City": "Stafford",
        "StateID": "TX",
        "url": "stafford-tx"
    },
    {
        "State": "Texas",
        "City": "Forney",
        "StateID": "TX",
        "url": "forney-tx"
    },
    {
        "State": "Texas",
        "City": "Big Spring",
        "StateID": "TX",
        "url": "bigspring-tx"
    },
    {
        "State": "Texas",
        "City": "Eagle Pass",
        "StateID": "TX",
        "url": "eaglepass-tx"
    },
    {
        "State": "Texas",
        "City": "Mount Pleasant",
        "StateID": "TX",
        "url": "mountpleasant-tx"
    },
    {
        "State": "Texas",
        "City": "Weatherford",
        "StateID": "TX",
        "url": "weatherford-tx"
    },
    {
        "State": "Texas",
        "City": "Colleyville",
        "StateID": "TX",
        "url": "colleyville-tx"
    },
    {
        "State": "Texas",
        "City": "Uvalde",
        "StateID": "TX",
        "url": "uvalde-tx"
    },
    {
        "State": "Texas",
        "City": "Stephenville",
        "StateID": "TX",
        "url": "stephenville-tx"
    },
    {
        "State": "Texas",
        "City": "Canyon Lake",
        "StateID": "TX",
        "url": "canyonlake-tx"
    },
    {
        "State": "Texas",
        "City": "Saginaw",
        "StateID": "TX",
        "url": "saginaw-tx"
    },
    {
        "State": "Texas",
        "City": "Raymondville",
        "StateID": "TX",
        "url": "raymondville-tx"
    },
    {
        "State": "Texas",
        "City": "Alvin",
        "StateID": "TX",
        "url": "alvin-tx"
    },
    {
        "State": "Texas",
        "City": "Glenn Heights",
        "StateID": "TX",
        "url": "glennheights-tx"
    },
    {
        "State": "Texas",
        "City": "Freeport",
        "StateID": "TX",
        "url": "freeport-tx"
    },
    {
        "State": "Texas",
        "City": "Andrews",
        "StateID": "TX",
        "url": "andrews-tx"
    },
    {
        "State": "Texas",
        "City": "La Porte",
        "StateID": "TX",
        "url": "laporte-tx"
    },
    {
        "State": "Texas",
        "City": "Sachse",
        "StateID": "TX",
        "url": "sachse-tx"
    },
    {
        "State": "Texas",
        "City": "Murphy",
        "StateID": "TX",
        "url": "murphy-tx"
    },
    {
        "State": "Texas",
        "City": "Pampa",
        "StateID": "TX",
        "url": "pampa-tx"
    },
    {
        "State": "Texas",
        "City": "Southlake",
        "StateID": "TX",
        "url": "southlake-tx"
    },
    {
        "State": "Texas",
        "City": "Palestine",
        "StateID": "TX",
        "url": "palestine-tx"
    },
    {
        "State": "Texas",
        "City": "Bcomer",
        "StateID": "TX",
        "url": "bcomer-tx"
    },
    {
        "State": "Texas",
        "City": "Sweetwater",
        "StateID": "TX",
        "url": "sweetwater-tx"
    },
    {
        "State": "Texas",
        "City": "Bellmead",
        "StateID": "TX",
        "url": "bellmead-tx"
    },
    {
        "State": "Texas",
        "City": "Fredericksburg",
        "StateID": "TX",
        "url": "fredericksburg-tx"
    },
    {
        "State": "Texas",
        "City": "Clute",
        "StateID": "TX",
        "url": "clute-tx"
    },
    {
        "State": "Texas",
        "City": "Bonham",
        "StateID": "TX",
        "url": "bonham-tx"
    },
    {
        "State": "Texas",
        "City": "Terrell",
        "StateID": "TX",
        "url": "terrell-tx"
    },
    {
        "State": "Texas",
        "City": "Lake Jackson",
        "StateID": "TX",
        "url": "lakejackson-tx"
    },
    {
        "State": "Texas",
        "City": "Rockport",
        "StateID": "TX",
        "url": "rockport-tx"
    },
    {
        "State": "Texas",
        "City": "Lockhart",
        "StateID": "TX",
        "url": "lockhart-tx"
    },
    {
        "State": "Texas",
        "City": "Portland",
        "StateID": "TX",
        "url": "portland-tx"
    },
    {
        "State": "Texas",
        "City": "Seabrook",
        "StateID": "TX",
        "url": "seabrook-tx"
    },
    {
        "State": "Texas",
        "City": "Helotes",
        "StateID": "TX",
        "url": "helotes-tx"
    },
    {
        "State": "Texas",
        "City": "Brownwood",
        "StateID": "TX",
        "url": "brownwood-tx"
    },
    {
        "State": "Texas",
        "City": "Liberty Hill",
        "StateID": "TX",
        "url": "libertyhill-tx"
    },
    {
        "State": "Texas",
        "City": "Beeville",
        "StateID": "TX",
        "url": "beeville-tx"
    },
    {
        "State": "Texas",
        "City": "Groves",
        "StateID": "TX",
        "url": "groves-tx"
    },
    {
        "State": "Texas",
        "City": "Vidor",
        "StateID": "TX",
        "url": "vidor-tx"
    },
    {
        "State": "Texas",
        "City": "Copperas Cove",
        "StateID": "TX",
        "url": "copperascove-tx"
    },
    {
        "State": "Texas",
        "City": "Prosper",
        "StateID": "TX",
        "url": "prosper-tx"
    },
    {
        "State": "Texas",
        "City": "White Settlement",
        "StateID": "TX",
        "url": "whitesettlement-tx"
    },
    {
        "State": "Texas",
        "City": "Seagoville",
        "StateID": "TX",
        "url": "seagoville-tx"
    },
    {
        "State": "Texas",
        "City": "Vernon",
        "StateID": "TX",
        "url": "vernon-tx"
    },
    {
        "State": "Texas",
        "City": "Kennedale",
        "StateID": "TX",
        "url": "kennedale-tx"
    },
    {
        "State": "Texas",
        "City": "Fair Oaks Ranch",
        "StateID": "TX",
        "url": "fairoaksranch-tx"
    },
    {
        "State": "Texas",
        "City": "Jacksboro",
        "StateID": "TX",
        "url": "jacksboro-tx"
    },
    {
        "State": "Texas",
        "City": "Joshua",
        "StateID": "TX",
        "url": "joshua-tx"
    },
    {
        "State": "Texas",
        "City": "Kingsland",
        "StateID": "TX",
        "url": "kingsland-tx"
    },
    {
        "State": "Texas",
        "City": "Manor",
        "StateID": "TX",
        "url": "manor-tx"
    },
    {
        "State": "Texas",
        "City": "Gatesville",
        "StateID": "TX",
        "url": "gatesville-tx"
    },
    {
        "State": "Texas",
        "City": "Ingleside",
        "StateID": "TX",
        "url": "ingleside-tx"
    },
    {
        "State": "Texas",
        "City": "Hidalgo",
        "StateID": "TX",
        "url": "hidalgo-tx"
    },
    {
        "State": "Texas",
        "City": "Red Oak",
        "StateID": "TX",
        "url": "redoak-tx"
    },
    {
        "State": "Texas",
        "City": "Kirby",
        "StateID": "TX",
        "url": "kirby-tx"
    },
    {
        "State": "Texas",
        "City": "Springtown",
        "StateID": "TX",
        "url": "springtown-tx"
    },
    {
        "State": "Texas",
        "City": "Silsbee",
        "StateID": "TX",
        "url": "silsbee-tx"
    },
    {
        "State": "Texas",
        "City": "El Campo",
        "StateID": "TX",
        "url": "elcampo-tx"
    },
    {
        "State": "Texas",
        "City": "Lamesa",
        "StateID": "TX",
        "url": "lamesa-tx"
    },
    {
        "State": "Texas",
        "City": "Hereford",
        "StateID": "TX",
        "url": "hereford-tx"
    },
    {
        "State": "Texas",
        "City": "Mexia",
        "StateID": "TX",
        "url": "mexia-tx"
    },
    {
        "State": "Texas",
        "City": "Hillsboro",
        "StateID": "TX",
        "url": "hillsboro-tx"
    },
    {
        "State": "Texas",
        "City": "Pecos",
        "StateID": "TX",
        "url": "pecos-tx"
    },
    {
        "State": "Texas",
        "City": "Crowley",
        "StateID": "TX",
        "url": "crowley-tx"
    },
    {
        "State": "Texas",
        "City": "Jacksonville",
        "StateID": "TX",
        "url": "jacksonville-tx"
    },
    {
        "State": "Texas",
        "City": "Bridgeport",
        "StateID": "TX",
        "url": "bridgeport-tx"
    },
    {
        "State": "Texas",
        "City": "Rusk",
        "StateID": "TX",
        "url": "rusk-tx"
    },
    {
        "State": "Texas",
        "City": "Gonzales",
        "StateID": "TX",
        "url": "gonzales-tx"
    },
    {
        "State": "Texas",
        "City": "Monahans",
        "StateID": "TX",
        "url": "monahans-tx"
    },
    {
        "State": "Texas",
        "City": "Roma",
        "StateID": "TX",
        "url": "roma-tx"
    },
    {
        "State": "Texas",
        "City": "Kaufman",
        "StateID": "TX",
        "url": "kaufman-tx"
    },
    {
        "State": "Texas",
        "City": "Devine",
        "StateID": "TX",
        "url": "devine-tx"
    },
    {
        "State": "Texas",
        "City": "Rockdale",
        "StateID": "TX",
        "url": "rockdale-tx"
    },
    {
        "State": "Texas",
        "City": "Henderson",
        "StateID": "TX",
        "url": "henderson-tx"
    },
    {
        "State": "Texas",
        "City": "Mount Vernon",
        "StateID": "TX",
        "url": "mountvernon-tx"
    },
    {
        "State": "Texas",
        "City": "Lytle",
        "StateID": "TX",
        "url": "lytle-tx"
    },
    {
        "State": "Texas",
        "City": "Sonora",
        "StateID": "TX",
        "url": "sonora-tx"
    },
    {
        "State": "Texas",
        "City": "Woodway",
        "StateID": "TX",
        "url": "woodway-tx"
    },
    {
        "State": "Texas",
        "City": "Aransas Pass",
        "StateID": "TX",
        "url": "aransaspass-tx"
    },
    {
        "State": "Texas",
        "City": "Raymondville",
        "StateID": "TX",
        "url": "raymondville-tx"
    },
    {
        "State": "Texas",
        "City": "Comfort",
        "StateID": "TX",
        "url": "comfort-tx"
    },
    {
        "State": "Texas",
        "City": "Navasota",
        "StateID": "TX",
        "url": "navasota-tx"
    },
    {
        "State": "Texas",
        "City": "Decatur",
        "StateID": "TX",
        "url": "decatur-tx"
    },
    {
        "State": "Texas",
        "City": "Bellville",
        "StateID": "TX",
        "url": "bellville-tx"
    },
    {
        "State": "Texas",
        "City": "Dalhart",
        "StateID": "TX",
        "url": "dalhart-tx"
    },
    {
        "State": "Texas",
        "City": "Dumas",
        "StateID": "TX",
        "url": "dumas-tx"
    },
    {
        "State": "Texas",
        "City": "Brookshire",
        "StateID": "TX",
        "url": "brookshire-tx"
    },
    {
        "State": "Texas",
        "City": "Eastland",
        "StateID": "TX",
        "url": "eastland-tx"
    },
    {
        "State": "Texas",
        "City": "Brookshire",
        "StateID": "TX",
        "url": "brookshire-tx"
    },
    {
        "State": "Texas",
        "City": "Robinson",
        "StateID": "TX",
        "url": "robinson-tx"
    },
    {
        "State": "Texas",
        "City": "San Benito",
        "StateID": "TX",
        "url": "sanbenito-tx"
    },
    {
        "State": "Texas",
        "City": "Sweetwater",
        "StateID": "TX",
        "url": "sweetwater-tx"
    },
    {
        "State": "Illinois",
        "City": "Chicago",
        "StateID": "IL",
        "url": "chicago-il"
    },
    {
        "State": "Illinois",
        "City": "Aurora",
        "StateID": "IL",
        "url": "aurora-il"
    },
    {
        "State": "Illinois",
        "City": "Naperville",
        "StateID": "IL",
        "url": "naperville-il"
    },
    {
        "State": "Illinois",
        "City": "Joliet",
        "StateID": "IL",
        "url": "joliet-il"
    },
    {
        "State": "Illinois",
        "City": "Rockford",
        "StateID": "IL",
        "url": "rockford-il"
    },
    {
        "State": "Illinois",
        "City": "Springfield",
        "StateID": "IL",
        "url": "springfield-il"
    },
    {
        "State": "Illinois",
        "City": "Elgin",
        "StateID": "IL",
        "url": "elgin-il"
    },
    {
        "State": "Illinois",
        "City": "Peoria",
        "StateID": "IL",
        "url": "peoria-il"
    },
    {
        "State": "Illinois",
        "City": "Champaign",
        "StateID": "IL",
        "url": "champaign-il"
    },
    {
        "State": "Illinois",
        "City": "Waukegan",
        "StateID": "IL",
        "url": "waukegan-il"
    },
    {
        "State": "Illinois",
        "City": "Cicero",
        "StateID": "IL",
        "url": "cicero-il"
    },
    {
        "State": "Illinois",
        "City": "Bloomington",
        "StateID": "IL",
        "url": "bloomington-il"
    },
    {
        "State": "Illinois",
        "City": "Arlington Heights",
        "StateID": "IL",
        "url": "arlingtonheights-il"
    },
    {
        "State": "Illinois",
        "City": "Evanston",
        "StateID": "IL",
        "url": "evanston-il"
    },
    {
        "State": "Illinois",
        "City": "Schaumburg",
        "StateID": "IL",
        "url": "schaumburg-il"
    },
    {
        "State": "Illinois",
        "City": "Bolingbrook",
        "StateID": "IL",
        "url": "bolingbrook-il"
    },
    {
        "State": "Illinois",
        "City": "Palatine",
        "StateID": "IL",
        "url": "palatine-il"
    },
    {
        "State": "Illinois",
        "City": "Skokie",
        "StateID": "IL",
        "url": "skokie-il"
    },
    {
        "State": "Illinois",
        "City": "Des Plaines",
        "StateID": "IL",
        "url": "desplaines-il"
    },
    {
        "State": "Illinois",
        "City": "Orland Park",
        "StateID": "IL",
        "url": "orlandpark-il"
    },
    {
        "State": "Illinois",
        "City": "Tinley Park",
        "StateID": "IL",
        "url": "tinleypark-il"
    },
    {
        "State": "Illinois",
        "City": "Oak Lawn",
        "StateID": "IL",
        "url": "oaklawn-il"
    },
    {
        "State": "Illinois",
        "City": "Berwyn",
        "StateID": "IL",
        "url": "berwyn-il"
    },
    {
        "State": "Illinois",
        "City": "Mount Prospect",
        "StateID": "IL",
        "url": "mountprospect-il"
    },
    {
        "State": "Illinois",
        "City": "Normal",
        "StateID": "IL",
        "url": "normal-il"
    },
    {
        "State": "Illinois",
        "City": "Wheaton",
        "StateID": "IL",
        "url": "wheaton-il"
    },
    {
        "State": "Illinois",
        "City": "Hoffman Estates",
        "StateID": "IL",
        "url": "hoffmanestates-il"
    },
    {
        "State": "Illinois",
        "City": "Oak Park",
        "StateID": "IL",
        "url": "oakpark-il"
    },
    {
        "State": "Illinois",
        "City": "Downers Grove",
        "StateID": "IL",
        "url": "downersgrove-il"
    },
    {
        "State": "Illinois",
        "City": "Glenview",
        "StateID": "IL",
        "url": "glenview-il"
    },
    {
        "State": "Illinois",
        "City": "Elmhurst",
        "StateID": "IL",
        "url": "elmhurst-il"
    },
    {
        "State": "Illinois",
        "City": "Lombard",
        "StateID": "IL",
        "url": "lombard-il"
    },
    {
        "State": "Illinois",
        "City": "DeKalb",
        "StateID": "IL",
        "url": "dekalb-il"
    },
    {
        "State": "Illinois",
        "City": "Belleville",
        "StateID": "IL",
        "url": "belleville-il"
    },
    {
        "State": "Illinois",
        "City": "Moline",
        "StateID": "IL",
        "url": "moline-il"
    },
    {
        "State": "Illinois",
        "City": "Buffalo Grove",
        "StateID": "IL",
        "url": "buffalogrove-il"
    },
    {
        "State": "Illinois",
        "City": "Urbana",
        "StateID": "IL",
        "url": "urbana-il"
    },
    {
        "State": "Illinois",
        "City": "Crystal Lake",
        "StateID": "IL",
        "url": "crystallake-il"
    },
    {
        "State": "Illinois",
        "City": "Quincy",
        "StateID": "IL",
        "url": "quincy-il"
    },
    {
        "State": "Illinois",
        "City": "Bartlett",
        "StateID": "IL",
        "url": "bartlett-il"
    },
    {
        "State": "Illinois",
        "City": "Streamwood",
        "StateID": "IL",
        "url": "streamwood-il"
    },
    {
        "State": "Illinois",
        "City": "Carol Stream",
        "StateID": "IL",
        "url": "carolstream-il"
    },
    {
        "State": "Illinois",
        "City": "Romeoville",
        "StateID": "IL",
        "url": "romeoville-il"
    },
    {
        "State": "Illinois",
        "City": "Plainfield",
        "StateID": "IL",
        "url": "plainfield-il"
    },
    {
        "State": "Illinois",
        "City": "Rock Island",
        "StateID": "IL",
        "url": "rockisland-il"
    },
    {
        "State": "Illinois",
        "City": "Hanover Park",
        "StateID": "IL",
        "url": "hanoverpark-il"
    },
    {
        "State": "Illinois",
        "City": "Carpentersville",
        "StateID": "IL",
        "url": "carpentersville-il"
    },
    {
        "State": "Illinois",
        "City": "Wheeling",
        "StateID": "IL",
        "url": "wheeling-il"
    },
    {
        "State": "Illinois",
        "City": "Park Ridge",
        "StateID": "IL",
        "url": "parkridge-il"
    },
    {
        "State": "Illinois",
        "City": "Addison",
        "StateID": "IL",
        "url": "addison-il"
    },
    {
        "State": "Illinois",
        "City": "Calumet City",
        "StateID": "IL",
        "url": "calumetcity-il"
    },
    {
        "State": "Illinois",
        "City": "Pekin",
        "StateID": "IL",
        "url": "pekin-il"
    },
    {
        "State": "Illinois",
        "City": "Northbrook",
        "StateID": "IL",
        "url": "northbrook-il"
    },
    {
        "State": "Illinois",
        "City": "Galesburg",
        "StateID": "IL",
        "url": "galesburg-il"
    },
    {
        "State": "Illinois",
        "City": "St. Charles",
        "StateID": "IL",
        "url": "st.charles-il"
    },
    {
        "State": "Illinois",
        "City": "Freeport",
        "StateID": "IL",
        "url": "freeport-il"
    },
    {
        "State": "Illinois",
        "City": "East St. Louis",
        "StateID": "IL",
        "url": "eastst.louis-il"
    },
    {
        "State": "Illinois",
        "City": "Danville",
        "StateID": "IL",
        "url": "danville-il"
    },
    {
        "State": "Illinois",
        "City": "North Chicago",
        "StateID": "IL",
        "url": "northchicago-il"
    },
    {
        "State": "Illinois",
        "City": "Alton",
        "StateID": "IL",
        "url": "alton-il"
    },
    {
        "State": "Illinois",
        "City": "Belvidere",
        "StateID": "IL",
        "url": "belvidere-il"
    },
    {
        "State": "Illinois",
        "City": "Woodridge",
        "StateID": "IL",
        "url": "woodridge-il"
    },
    {
        "State": "Illinois",
        "City": "Granite City",
        "StateID": "IL",
        "url": "granitecity-il"
    },
    {
        "State": "Illinois",
        "City": "Glen Ellyn",
        "StateID": "IL",
        "url": "glenellyn-il"
    },
    {
        "State": "Illinois",
        "City": "McHenry",
        "StateID": "IL",
        "url": "mchenry-il"
    },
    {
        "State": "Illinois",
        "City": "Batavia",
        "StateID": "IL",
        "url": "batavia-il"
    },
    {
        "State": "Illinois",
        "City": "Mundelein",
        "StateID": "IL",
        "url": "mundelein-il"
    },
    {
        "State": "Illinois",
        "City": "Highland Park",
        "StateID": "IL",
        "url": "highlandpark-il"
    },
    {
        "State": "Illinois",
        "City": "Niles",
        "StateID": "IL",
        "url": "niles-il"
    },
    {
        "State": "Illinois",
        "City": "Villa Park",
        "StateID": "IL",
        "url": "villapark-il"
    },
    {
        "State": "Illinois",
        "City": "O'Fallon",
        "StateID": "IL",
        "url": "o'fallon-il"
    },
    {
        "State": "Illinois",
        "City": "Burbank",
        "StateID": "IL",
        "url": "burbank-il"
    },
    {
        "State": "Illinois",
        "City": "Lansing",
        "StateID": "IL",
        "url": "lansing-il"
    },
    {
        "State": "Illinois",
        "City": "New Lenox",
        "StateID": "IL",
        "url": "newlenox-il"
    },
    {
        "State": "Illinois",
        "City": "Crest Hill",
        "StateID": "IL",
        "url": "cresthill-il"
    },
    {
        "State": "Illinois",
        "City": "Oswego",
        "StateID": "IL",
        "url": "oswego-il"
    },
    {
        "State": "Illinois",
        "City": "West Chicago",
        "StateID": "IL",
        "url": "westchicago-il"
    },
    {
        "State": "Illinois",
        "City": "Loves Park",
        "StateID": "IL",
        "url": "lovespark-il"
    },
    {
        "State": "Illinois",
        "City": "Mokena",
        "StateID": "IL",
        "url": "mokena-il"
    },
    {
        "State": "Illinois",
        "City": "Morton",
        "StateID": "IL",
        "url": "morton-il"
    },
    {
        "State": "Illinois",
        "City": "East Peoria",
        "StateID": "IL",
        "url": "eastpeoria-il"
    },
    {
        "State": "Illinois",
        "City": "Rolling Meadows",
        "StateID": "IL",
        "url": "rollingmeadows-il"
    },
    {
        "State": "Illinois",
        "City": "South Elgin",
        "StateID": "IL",
        "url": "southelgin-il"
    },
    {
        "State": "Illinois",
        "City": "Yorkville",
        "StateID": "IL",
        "url": "yorkville-il"
    },
    {
        "State": "Illinois",
        "City": "Dixon",
        "StateID": "IL",
        "url": "dixon-il"
    },
    {
        "State": "Illinois",
        "City": "Zion",
        "StateID": "IL",
        "url": "zion-il"
    },
    {
        "State": "Illinois",
        "City": "Wood Dale",
        "StateID": "IL",
        "url": "wooddale-il"
    },
    {
        "State": "Illinois",
        "City": "Roselle",
        "StateID": "IL",
        "url": "roselle-il"
    },
    {
        "State": "Illinois",
        "City": "Westmont",
        "StateID": "IL",
        "url": "westmont-il"
    },
    {
        "State": "Illinois",
        "City": "Ottawa",
        "StateID": "IL",
        "url": "ottawa-il"
    },
    {
        "State": "Illinois",
        "City": "Bensenville",
        "StateID": "IL",
        "url": "bensenville-il"
    },
    {
        "State": "Illinois",
        "City": "Shorewood",
        "StateID": "IL",
        "url": "shorewood-il"
    },
    {
        "State": "Illinois",
        "City": "Warrenville",
        "StateID": "IL",
        "url": "warrenville-il"
    },
    {
        "State": "Illinois",
        "City": "Hickory Hills",
        "StateID": "IL",
        "url": "hickoryhills-il"
    },
    {
        "State": "Illinois",
        "City": "Lockport",
        "StateID": "IL",
        "url": "lockport-il"
    },
    {
        "State": "Illinois",
        "City": "La Grange",
        "StateID": "IL",
        "url": "lagrange-il"
    },
    {
        "State": "Illinois",
        "City": "Sycamore",
        "StateID": "IL",
        "url": "sycamore-il"
    },
    {
        "State": "Illinois",
        "City": "Darien",
        "StateID": "IL",
        "url": "darien-il"
    },
    {
        "State": "Illinois",
        "City": "Washington",
        "StateID": "IL",
        "url": "washington-il"
    },
    {
        "State": "Illinois",
        "City": "Crystal Lake",
        "StateID": "IL",
        "url": "crystallake-il"
    },
    {
        "State": "Florida",
        "City": "Jacksonville",
        "StateID": "FL",
        "url": "jacksonville-fl"
    },
    {
        "State": "Florida",
        "City": "Miami",
        "StateID": "FL",
        "url": "miami-fl"
    },
    {
        "State": "Florida",
        "City": "Tampa",
        "StateID": "FL",
        "url": "tampa-fl"
    },
    {
        "State": "Florida",
        "City": "Orlando",
        "StateID": "FL",
        "url": "orlando-fl"
    },
    {
        "State": "Florida",
        "City": "St. Petersburg",
        "StateID": "FL",
        "url": "st.petersburg-fl"
    },
    {
        "State": "Florida",
        "City": "Hialeah",
        "StateID": "FL",
        "url": "hialeah-fl"
    },
    {
        "State": "Florida",
        "City": "Port St. Lucie",
        "StateID": "FL",
        "url": "portst.lucie-fl"
    },
    {
        "State": "Florida",
        "City": "Cape Coral",
        "StateID": "FL",
        "url": "capecoral-fl"
    },
    {
        "State": "Florida",
        "City": "Tallahassee",
        "StateID": "FL",
        "url": "tallahassee-fl"
    },
    {
        "State": "Florida",
        "City": "Fort Lauderdale",
        "StateID": "FL",
        "url": "fortlauderdale-fl"
    },
    {
        "State": "Florida",
        "City": "Pembroke Pines",
        "StateID": "FL",
        "url": "pembrokepines-fl"
    },
    {
        "State": "Florida",
        "City": "Hollywood",
        "StateID": "FL",
        "url": "hollywood-fl"
    },
    {
        "State": "Florida",
        "City": "Miramar",
        "StateID": "FL",
        "url": "miramar-fl"
    },
    {
        "State": "Florida",
        "City": "Gainesville",
        "StateID": "FL",
        "url": "gainesville-fl"
    },
    {
        "State": "Florida",
        "City": "Coral Springs",
        "StateID": "FL",
        "url": "coralsprings-fl"
    },
    {
        "State": "Florida",
        "City": "Clearwater",
        "StateID": "FL",
        "url": "clearwater-fl"
    },
    {
        "State": "Florida",
        "City": "Palm Bay",
        "StateID": "FL",
        "url": "palmbay-fl"
    },
    {
        "State": "Florida",
        "City": "West Palm Beach",
        "StateID": "FL",
        "url": "westpalmbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Lakeland",
        "StateID": "FL",
        "url": "lakeland-fl"
    },
    {
        "State": "Florida",
        "City": "Pompano Beach",
        "StateID": "FL",
        "url": "pompanobeach-fl"
    },
    {
        "State": "Florida",
        "City": "Miami Gardens",
        "StateID": "FL",
        "url": "miamigardens-fl"
    },
    {
        "State": "Florida",
        "City": "Deltona",
        "StateID": "FL",
        "url": "deltona-fl"
    },
    {
        "State": "Florida",
        "City": "Plantation",
        "StateID": "FL",
        "url": "plantation-fl"
    },
    {
        "State": "Florida",
        "City": "Sunrise",
        "StateID": "FL",
        "url": "sunrise-fl"
    },
    {
        "State": "Florida",
        "City": "Boca Raton",
        "StateID": "FL",
        "url": "bocaraton-fl"
    },
    {
        "State": "Florida",
        "City": "Melbourne",
        "StateID": "FL",
        "url": "melbourne-fl"
    },
    {
        "State": "Florida",
        "City": "Palm Coast",
        "StateID": "FL",
        "url": "palmcoast-fl"
    },
    {
        "State": "Florida",
        "City": "Largo",
        "StateID": "FL",
        "url": "largo-fl"
    },
    {
        "State": "Florida",
        "City": "Deerfield Beach",
        "StateID": "FL",
        "url": "deerfieldbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Fort Myers",
        "StateID": "FL",
        "url": "fortmyers-fl"
    },
    {
        "State": "Florida",
        "City": "Boynton Beach",
        "StateID": "FL",
        "url": "boyntonbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Doral",
        "StateID": "FL",
        "url": "doral-fl"
    },
    {
        "State": "Florida",
        "City": "Homestead",
        "StateID": "FL",
        "url": "homestead-fl"
    },
    {
        "State": "Florida",
        "City": "Kissimmee",
        "StateID": "FL",
        "url": "kissimmee-fl"
    },
    {
        "State": "Florida",
        "City": "North Port",
        "StateID": "FL",
        "url": "northport-fl"
    },
    {
        "State": "Florida",
        "City": "Delray Beach",
        "StateID": "FL",
        "url": "delraybeach-fl"
    },
    {
        "State": "Florida",
        "City": "Jupiter",
        "StateID": "FL",
        "url": "jupiter-fl"
    },
    {
        "State": "Florida",
        "City": "Port Orange",
        "StateID": "FL",
        "url": "portorange-fl"
    },
    {
        "State": "Florida",
        "City": "Sarasota",
        "StateID": "FL",
        "url": "sarasota-fl"
    },
    {
        "State": "Florida",
        "City": "Daytona Beach",
        "StateID": "FL",
        "url": "daytonabeach-fl"
    },
    {
        "State": "Florida",
        "City": "Ocala",
        "StateID": "FL",
        "url": "ocala-fl"
    },
    {
        "State": "Florida",
        "City": "Coral Gables",
        "StateID": "FL",
        "url": "coralgables-fl"
    },
    {
        "State": "Florida",
        "City": "Weston",
        "StateID": "FL",
        "url": "weston-fl"
    },
    {
        "State": "Florida",
        "City": "Sanford",
        "StateID": "FL",
        "url": "sanford-fl"
    },
    {
        "State": "Florida",
        "City": "Pinellas Park",
        "StateID": "FL",
        "url": "pinellaspark-fl"
    },
    {
        "State": "Florida",
        "City": "Apopka",
        "StateID": "FL",
        "url": "apopka-fl"
    },
    {
        "State": "Florida",
        "City": "Bradenton",
        "StateID": "FL",
        "url": "bradenton-fl"
    },
    {
        "State": "Florida",
        "City": "Pensacola",
        "StateID": "FL",
        "url": "pensacola-fl"
    },
    {
        "State": "Florida",
        "City": "Winter Garden",
        "StateID": "FL",
        "url": "wintergarden-fl"
    },
    {
        "State": "Florida",
        "City": "Palm Beach Gardens",
        "StateID": "FL",
        "url": "palmbeachgardens-fl"
    },
    {
        "State": "Florida",
        "City": "Bonita Springs",
        "StateID": "FL",
        "url": "bonitasprings-fl"
    },
    {
        "State": "Florida",
        "City": "Fort Pierce",
        "StateID": "FL",
        "url": "fortpierce-fl"
    },
    {
        "State": "Florida",
        "City": "Kissimmee",
        "StateID": "FL",
        "url": "kissimmee-fl"
    },
    {
        "State": "Florida",
        "City": "St. Cloud",
        "StateID": "FL",
        "url": "st.cloud-fl"
    },
    {
        "State": "Florida",
        "City": "Winter Haven",
        "StateID": "FL",
        "url": "winterhaven-fl"
    },
    {
        "State": "Florida",
        "City": "Coconut Creek",
        "StateID": "FL",
        "url": "coconutcreek-fl"
    },
    {
        "State": "Florida",
        "City": "Altamonte Springs",
        "StateID": "FL",
        "url": "altamontesprings-fl"
    },
    {
        "State": "Florida",
        "City": "North Miami",
        "StateID": "FL",
        "url": "northmiami-fl"
    },
    {
        "State": "Florida",
        "City": "Dunedin",
        "StateID": "FL",
        "url": "dunedin-fl"
    },
    {
        "State": "Florida",
        "City": "Rockledge",
        "StateID": "FL",
        "url": "rockledge-fl"
    },
    {
        "State": "Florida",
        "City": "Casselberry",
        "StateID": "FL",
        "url": "casselberry-fl"
    },
    {
        "State": "Florida",
        "City": "Margate",
        "StateID": "FL",
        "url": "margate-fl"
    },
    {
        "State": "Florida",
        "City": "Clermont",
        "StateID": "FL",
        "url": "clermont-fl"
    },
    {
        "State": "Florida",
        "City": "Royal Palm Beach",
        "StateID": "FL",
        "url": "royalpalmbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Miami Lakes",
        "StateID": "FL",
        "url": "miamilakes-fl"
    },
    {
        "State": "Florida",
        "City": "Aventura",
        "StateID": "FL",
        "url": "aventura-fl"
    },
    {
        "State": "Florida",
        "City": "Winter Springs",
        "StateID": "FL",
        "url": "wintersprings-fl"
    },
    {
        "State": "Florida",
        "City": "Oakland Park",
        "StateID": "FL",
        "url": "oaklandpark-fl"
    },
    {
        "State": "Florida",
        "City": "North Lauderdale",
        "StateID": "FL",
        "url": "northlauderdale-fl"
    },
    {
        "State": "Florida",
        "City": "Hallandale Beach",
        "StateID": "FL",
        "url": "hallandalebeach-fl"
    },
    {
        "State": "Florida",
        "City": "Greenacres",
        "StateID": "FL",
        "url": "greenacres-fl"
    },
    {
        "State": "Florida",
        "City": "Ocoee",
        "StateID": "FL",
        "url": "ocoee-fl"
    },
    {
        "State": "Florida",
        "City": "Titusville",
        "StateID": "FL",
        "url": "titusville-fl"
    },
    {
        "State": "Florida",
        "City": "Riviera Beach",
        "StateID": "FL",
        "url": "rivierabeach-fl"
    },
    {
        "State": "Florida",
        "City": "Parkland",
        "StateID": "FL",
        "url": "parkland-fl"
    },
    {
        "State": "Florida",
        "City": "Key West",
        "StateID": "FL",
        "url": "keywest-fl"
    },
    {
        "State": "Florida",
        "City": "Cutler Bay",
        "StateID": "FL",
        "url": "cutlerbay-fl"
    },
    {
        "State": "Florida",
        "City": "Sebastian",
        "StateID": "FL",
        "url": "sebastian-fl"
    },
    {
        "State": "Florida",
        "City": "Lake Worth Beach",
        "StateID": "FL",
        "url": "lakeworthbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Marco Island",
        "StateID": "FL",
        "url": "marcoisland-fl"
    },
    {
        "State": "Florida",
        "City": "Zephyrhills",
        "StateID": "FL",
        "url": "zephyrhills-fl"
    },
    {
        "State": "Florida",
        "City": "Tarpon Springs",
        "StateID": "FL",
        "url": "tarponsprings-fl"
    },
    {
        "State": "Florida",
        "City": "Palatka",
        "StateID": "FL",
        "url": "palatka-fl"
    },
    {
        "State": "Florida",
        "City": "Cocoa",
        "StateID": "FL",
        "url": "cocoa-fl"
    },
    {
        "State": "Florida",
        "City": "Tavares",
        "StateID": "FL",
        "url": "tavares-fl"
    },
    {
        "State": "Florida",
        "City": "Fernandina Beach",
        "StateID": "FL",
        "url": "fernandinabeach-fl"
    },
    {
        "State": "Florida",
        "City": "Vero Beach",
        "StateID": "FL",
        "url": "verobeach-fl"
    },
    {
        "State": "Florida",
        "City": "Eustis",
        "StateID": "FL",
        "url": "eustis-fl"
    },
    {
        "State": "Florida",
        "City": "Perry",
        "StateID": "FL",
        "url": "perry-fl"
    },
    {
        "State": "Florida",
        "City": "Venice",
        "StateID": "FL",
        "url": "venice-fl"
    },
    {
        "State": "Florida",
        "City": "Lake Wales",
        "StateID": "FL",
        "url": "lakewales-fl"
    },
    {
        "State": "Florida",
        "City": "Haines City",
        "StateID": "FL",
        "url": "hainescity-fl"
    },
    {
        "State": "Florida",
        "City": "New Smyrna Beach",
        "StateID": "FL",
        "url": "newsmyrnabeach-fl"
    },
    {
        "State": "Florida",
        "City": "Panama City",
        "StateID": "FL",
        "url": "panamacity-fl"
    },
    {
        "State": "Florida",
        "City": "Holly Hill",
        "StateID": "FL",
        "url": "hollyhill-fl"
    },
    {
        "State": "Florida",
        "City": "Mount Dora",
        "StateID": "FL",
        "url": "mountdora-fl"
    },
    {
        "State": "Florida",
        "City": "St. Augustine",
        "StateID": "FL",
        "url": "st.augustine-fl"
    },
    {
        "State": "Florida",
        "City": "Clewiston",
        "StateID": "FL",
        "url": "clewiston-fl"
    },
    {
        "State": "Florida",
        "City": "Live Oak",
        "StateID": "FL",
        "url": "liveoak-fl"
    },
    {
        "State": "Florida",
        "City": "Belle Glade",
        "StateID": "FL",
        "url": "belleglade-fl"
    },
    {
        "State": "Florida",
        "City": "Oviedo",
        "StateID": "FL",
        "url": "oviedo-fl"
    },
    {
        "State": "Florida",
        "City": "South Miami",
        "StateID": "FL",
        "url": "southmiami-fl"
    },
    {
        "State": "Florida",
        "City": "Starke",
        "StateID": "FL",
        "url": "starke-fl"
    },
    {
        "State": "Florida",
        "City": "West Melbourne",
        "StateID": "FL",
        "url": "westmelbourne-fl"
    },
    {
        "State": "Florida",
        "City": "High Springs",
        "StateID": "FL",
        "url": "highsprings-fl"
    },
    {
        "State": "Florida",
        "City": "Maitland",
        "StateID": "FL",
        "url": "maitland-fl"
    },
    {
        "State": "Florida",
        "City": "Brooksville",
        "StateID": "FL",
        "url": "brooksville-fl"
    },
    {
        "State": "Florida",
        "City": "Belleview",
        "StateID": "FL",
        "url": "belleview-fl"
    },
    {
        "State": "Florida",
        "City": "Gulf Breeze",
        "StateID": "FL",
        "url": "gulfbreeze-fl"
    },
    {
        "State": "Florida",
        "City": "Lantana",
        "StateID": "FL",
        "url": "lantana-fl"
    },
    {
        "State": "Florida",
        "City": "Leesburg",
        "StateID": "FL",
        "url": "leesburg-fl"
    },
    {
        "State": "Florida",
        "City": "Palmetto",
        "StateID": "FL",
        "url": "palmetto-fl"
    },
    {
        "State": "Florida",
        "City": "Fort Meade",
        "StateID": "FL",
        "url": "fortmeade-fl"
    },
    {
        "State": "Florida",
        "City": "Marianna",
        "StateID": "FL",
        "url": "marianna-fl"
    },
    {
        "State": "Florida",
        "City": "Blountstown",
        "StateID": "FL",
        "url": "blountstown-fl"
    },
    {
        "State": "Florida",
        "City": "Chattahoochee",
        "StateID": "FL",
        "url": "chattahoochee-fl"
    },
    {
        "State": "Florida",
        "City": "Madison",
        "StateID": "FL",
        "url": "madison-fl"
    },
    {
        "State": "Florida",
        "City": "Crescent City",
        "StateID": "FL",
        "url": "crescentcity-fl"
    },
    {
        "State": "Florida",
        "City": "Williston",
        "StateID": "FL",
        "url": "williston-fl"
    },
    {
        "State": "Florida",
        "City": "Dunnellon",
        "StateID": "FL",
        "url": "dunnellon-fl"
    },
    {
        "State": "Florida",
        "City": "LaBelle",
        "StateID": "FL",
        "url": "labelle-fl"
    },
    {
        "State": "Florida",
        "City": "Avon Park",
        "StateID": "FL",
        "url": "avonpark-fl"
    },
    {
        "State": "Florida",
        "City": "Homosassa",
        "StateID": "FL",
        "url": "homosassa-fl"
    },
    {
        "State": "Florida",
        "City": "Crystal River",
        "StateID": "FL",
        "url": "crystalriver-fl"
    },
    {
        "State": "Florida",
        "City": "Alachua",
        "StateID": "FL",
        "url": "alachua-fl"
    },
    {
        "State": "Florida",
        "City": "Oldsmar",
        "StateID": "FL",
        "url": "oldsmar-fl"
    },
    {
        "State": "Florida",
        "City": "Macclenny",
        "StateID": "FL",
        "url": "macclenny-fl"
    },
    {
        "State": "Florida",
        "City": "Longwood",
        "StateID": "FL",
        "url": "longwood-fl"
    },
    {
        "State": "Florida",
        "City": "Opa-locka",
        "StateID": "FL",
        "url": "opa-locka-fl"
    },
    {
        "State": "Florida",
        "City": "Gulfport",
        "StateID": "FL",
        "url": "gulfport-fl"
    },
    {
        "State": "Florida",
        "City": "Treasure Island",
        "StateID": "FL",
        "url": "treasureisland-fl"
    },
    {
        "State": "Florida",
        "City": "Auburndale",
        "StateID": "FL",
        "url": "auburndale-fl"
    },
    {
        "State": "Florida",
        "City": "Lake Mary",
        "StateID": "FL",
        "url": "lakemary-fl"
    },
    {
        "State": "Florida",
        "City": "Groveland",
        "StateID": "FL",
        "url": "groveland-fl"
    },
    {
        "State": "Florida",
        "City": "Port St. Joe",
        "StateID": "FL",
        "url": "portst.joe-fl"
    },
    {
        "State": "Florida",
        "City": "Everglades",
        "StateID": "FL",
        "url": "everglades-fl"
    },
    {
        "State": "Florida",
        "City": "Fellsmere",
        "StateID": "FL",
        "url": "fellsmere-fl"
    },
    {
        "State": "Florida",
        "City": "Milton",
        "StateID": "FL",
        "url": "milton-fl"
    },
    {
        "State": "Florida",
        "City": "Sweetwater",
        "StateID": "FL",
        "url": "sweetwater-fl"
    },
    {
        "State": "Florida",
        "City": "Indian Harbour Beach",
        "StateID": "FL",
        "url": "indianharbourbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Satellite Beach",
        "StateID": "FL",
        "url": "satellitebeach-fl"
    },
    {
        "State": "Florida",
        "City": "Bushnell",
        "StateID": "FL",
        "url": "bushnell-fl"
    },
    {
        "State": "Florida",
        "City": "Inverness",
        "StateID": "FL",
        "url": "inverness-fl"
    },
    {
        "State": "Florida",
        "City": "Waldo",
        "StateID": "FL",
        "url": "waldo-fl"
    },
    {
        "State": "Florida",
        "City": "St. Pete Beach",
        "StateID": "FL",
        "url": "st.petebeach-fl"
    },
    {
        "State": "Florida",
        "City": "North Bay Village",
        "StateID": "FL",
        "url": "northbayvillage-fl"
    },
    {
        "State": "Florida",
        "City": "Belle Isle",
        "StateID": "FL",
        "url": "belleisle-fl"
    },
    {
        "State": "Florida",
        "City": "Fort Walton Beach",
        "StateID": "FL",
        "url": "fortwaltonbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Lake Alfred",
        "StateID": "FL",
        "url": "lakealfred-fl"
    },
    {
        "State": "Florida",
        "City": "Winter Park",
        "StateID": "FL",
        "url": "winterpark-fl"
    },
    {
        "State": "Florida",
        "City": "Bowling Green",
        "StateID": "FL",
        "url": "bowlinggreen-fl"
    },
    {
        "State": "Florida",
        "City": "Atlantic Beach",
        "StateID": "FL",
        "url": "atlanticbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Neptune Beach",
        "StateID": "FL",
        "url": "neptunebeach-fl"
    },
    {
        "State": "Florida",
        "City": "Kenneth City",
        "StateID": "FL",
        "url": "kennethcity-fl"
    },
    {
        "State": "Florida",
        "City": "Pembroke Park",
        "StateID": "FL",
        "url": "pembrokepark-fl"
    },
    {
        "State": "Florida",
        "City": "Surfside",
        "StateID": "FL",
        "url": "surfside-fl"
    },
    {
        "State": "Florida",
        "City": "Lake Butler",
        "StateID": "FL",
        "url": "lakebutler-fl"
    },
    {
        "State": "Florida",
        "City": "Biscayne Park",
        "StateID": "FL",
        "url": "biscaynepark-fl"
    },
    {
        "State": "Florida",
        "City": "Palm Beach",
        "StateID": "FL",
        "url": "palmbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Golden Beach",
        "StateID": "FL",
        "url": "goldenbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Polk City",
        "StateID": "FL",
        "url": "polkcity-fl"
    },
    {
        "State": "Florida",
        "City": "Midway",
        "StateID": "FL",
        "url": "midway-fl"
    },
    {
        "State": "Florida",
        "City": "Winter Springs",
        "StateID": "FL",
        "url": "wintersprings-fl"
    },
    {
        "State": "Florida",
        "City": "Fruitland Park",
        "StateID": "FL",
        "url": "fruitlandpark-fl"
    },
    {
        "State": "Florida",
        "City": "Bal Harbour",
        "StateID": "FL",
        "url": "balharbour-fl"
    },
    {
        "State": "Florida",
        "City": "Wewahitchka",
        "StateID": "FL",
        "url": "wewahitchka-fl"
    },
    {
        "State": "Florida",
        "City": "St. Leo",
        "StateID": "FL",
        "url": "st.leo-fl"
    },
    {
        "State": "Florida",
        "City": "Umatilla",
        "StateID": "FL",
        "url": "umatilla-fl"
    },
    {
        "State": "Florida",
        "City": "Wauchula",
        "StateID": "FL",
        "url": "wauchula-fl"
    },
    {
        "State": "Florida",
        "City": "Lauderhill",
        "StateID": "FL",
        "url": "lauderhill-fl"
    },
    {
        "State": "Florida",
        "City": "Lake Helen",
        "StateID": "FL",
        "url": "lakehelen-fl"
    },
    {
        "State": "Florida",
        "City": "Cape Canaveral",
        "StateID": "FL",
        "url": "capecanaveral-fl"
    },
    {
        "State": "Florida",
        "City": "Indian Rocks Beach",
        "StateID": "FL",
        "url": "indianrocksbeach-fl"
    },
    {
        "State": "Florida",
        "City": "St. Marks",
        "StateID": "FL",
        "url": "st.marks-fl"
    },
    {
        "State": "Florida",
        "City": "Pahokee",
        "StateID": "FL",
        "url": "pahokee-fl"
    },
    {
        "State": "Florida",
        "City": "Mangonia Park",
        "StateID": "FL",
        "url": "mangoniapark-fl"
    },
    {
        "State": "Florida",
        "City": "Kenneth City",
        "StateID": "FL",
        "url": "kennethcity-fl"
    },
    {
        "State": "Florida",
        "City": "Fort White",
        "StateID": "FL",
        "url": "fortwhite-fl"
    },
    {
        "State": "Florida",
        "City": "Deerfield Beach",
        "StateID": "FL",
        "url": "deerfieldbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Apalachicola",
        "StateID": "FL",
        "url": "apalachicola-fl"
    },
    {
        "State": "Florida",
        "City": "Howey-in-the-Hills",
        "StateID": "FL",
        "url": "howey-in-the-hills-fl"
    },
    {
        "State": "Florida",
        "City": "Lake Clarke Shores",
        "StateID": "FL",
        "url": "lakeclarkeshores-fl"
    },
    {
        "State": "Florida",
        "City": "North Redington Beach",
        "StateID": "FL",
        "url": "northredingtonbeach-fl"
    },
    {
        "State": "Florida",
        "City": "Webster",
        "StateID": "FL",
        "url": "webster-fl"
    },
    {
        "State": "Florida",
        "City": "Malabar",
        "StateID": "FL",
        "url": "malabar-fl"
    },
    {
        "State": "Florida",
        "City": "Dundee",
        "StateID": "FL",
        "url": "dundee-fl"
    },
    {
        "State": "Florida",
        "City": "Hypoluxo",
        "StateID": "FL",
        "url": "hypoluxo-fl"
    },
    {
        "State": "Florida",
        "City": "Cutler Ridge",
        "StateID": "FL",
        "url": "cutlerridge-fl"
    },
    {
        "State": "Florida",
        "City": "Fort Green",
        "StateID": "FL",
        "url": "fortgreen-fl"
    },
    {
        "State": "Florida",
        "City": "Montverde",
        "StateID": "FL",
        "url": "montverde-fl"
    },
    {
        "State": "Florida",
        "City": "Lake Placid",
        "StateID": "FL",
        "url": "lakeplacid-fl"
    },
    {
        "State": "Florida",
        "City": "Madeira Beach",
        "StateID": "FL",
        "url": "madeirabeach-fl"
    },
    {
        "State": "Florida",
        "City": "Dade City",
        "StateID": "FL",
        "url": "dadecity-fl"
    },
    {
        "State": "Florida",
        "City": "Havana",
        "StateID": "FL",
        "url": "havana-fl"
    },
    {
        "State": "Florida",
        "City": "Windermere",
        "StateID": "FL",
        "url": "windermere-fl"
    },
    {
        "State": "Florida",
        "City": "Loxahatchee Groves",
        "StateID": "FL",
        "url": "loxahatcheegroves-fl"
    },
    {
        "State": "Florida",
        "City": "Bronson",
        "StateID": "FL",
        "url": "bronson-fl"
    },
    {
        "State": "Florida",
        "City": "Zolfo Springs",
        "StateID": "FL",
        "url": "zolfosprings-fl"
    },
    {
        "State": "Florida",
        "City": "Brooker",
        "StateID": "FL",
        "url": "brooker-fl"
    },
    {
        "State": "Florida",
        "City": "Hastings",
        "StateID": "FL",
        "url": "hastings-fl"
    },
    {
        "State": "Missouri",
        "City": "Kansas City",
        "StateID": "MO",
        "url": "kansascity-mo"
    },
    {
        "State": "Missouri",
        "City": "St. Louis",
        "StateID": "MO",
        "url": "st.louis-mo"
    },
    {
        "State": "Missouri",
        "City": "Springfield",
        "StateID": "MO",
        "url": "springfield-mo"
    },
    {
        "State": "Missouri",
        "City": "Columbia",
        "StateID": "MO",
        "url": "columbia-mo"
    },
    {
        "State": "Missouri",
        "City": "Independence",
        "StateID": "MO",
        "url": "independence-mo"
    },
    {
        "State": "Missouri",
        "City": "Lee's Summit",
        "StateID": "MO",
        "url": "lee'ssummit-mo"
    },
    {
        "State": "Missouri",
        "City": "O'Fallon",
        "StateID": "MO",
        "url": "o'fallon-mo"
    },
    {
        "State": "Missouri",
        "City": "St. Joseph",
        "StateID": "MO",
        "url": "st.joseph-mo"
    },
    {
        "State": "Missouri",
        "City": "St. Charles",
        "StateID": "MO",
        "url": "st.charles-mo"
    },
    {
        "State": "Missouri",
        "City": "St. Peters",
        "StateID": "MO",
        "url": "st.peters-mo"
    },
    {
        "State": "Missouri",
        "City": "Blue Springs",
        "StateID": "MO",
        "url": "bluesprings-mo"
    },
    {
        "State": "Missouri",
        "City": "Joplin",
        "StateID": "MO",
        "url": "joplin-mo"
    },
    {
        "State": "Missouri",
        "City": "Florissant",
        "StateID": "MO",
        "url": "florissant-mo"
    },
    {
        "State": "Missouri",
        "City": "Chesterfield",
        "StateID": "MO",
        "url": "chesterfield-mo"
    },
    {
        "State": "Missouri",
        "City": "Jefferson City",
        "StateID": "MO",
        "url": "jeffersoncity-mo"
    },
    {
        "State": "Missouri",
        "City": "Wentzville",
        "StateID": "MO",
        "url": "wentzville-mo"
    },
    {
        "State": "Missouri",
        "City": "Cape Girardeau",
        "StateID": "MO",
        "url": "capegirardeau-mo"
    },
    {
        "State": "Missouri",
        "City": "Wildwood",
        "StateID": "MO",
        "url": "wildwood-mo"
    },
    {
        "State": "Missouri",
        "City": "University City",
        "StateID": "MO",
        "url": "universitycity-mo"
    },
    {
        "State": "Missouri",
        "City": "Ballwin",
        "StateID": "MO",
        "url": "ballwin-mo"
    },
    {
        "State": "Missouri",
        "City": "Raytown",
        "StateID": "MO",
        "url": "raytown-mo"
    },
    {
        "State": "Missouri",
        "City": "Liberty",
        "StateID": "MO",
        "url": "liberty-mo"
    },
    {
        "State": "Missouri",
        "City": "Kirkwood",
        "StateID": "MO",
        "url": "kirkwood-mo"
    },
    {
        "State": "Missouri",
        "City": "Maryland Heights",
        "StateID": "MO",
        "url": "marylandheights-mo"
    },
    {
        "State": "Missouri",
        "City": "Gladstone",
        "StateID": "MO",
        "url": "gladstone-mo"
    },
    {
        "State": "Missouri",
        "City": "Belton",
        "StateID": "MO",
        "url": "belton-mo"
    },
    {
        "State": "Missouri",
        "City": "Arnold",
        "StateID": "MO",
        "url": "arnold-mo"
    },
    {
        "State": "Missouri",
        "City": "Hazelwood",
        "StateID": "MO",
        "url": "hazelwood-mo"
    },
    {
        "State": "Missouri",
        "City": "Webster Groves",
        "StateID": "MO",
        "url": "webstergroves-mo"
    },
    {
        "State": "Missouri",
        "City": "Raymore",
        "StateID": "MO",
        "url": "raymore-mo"
    },
    {
        "State": "Missouri",
        "City": "Sedalia",
        "StateID": "MO",
        "url": "sedalia-mo"
    },
    {
        "State": "Missouri",
        "City": "Warrensburg",
        "StateID": "MO",
        "url": "warrensburg-mo"
    },
    {
        "State": "Missouri",
        "City": "Nixa",
        "StateID": "MO",
        "url": "nixa-mo"
    },
    {
        "State": "Missouri",
        "City": "Ferguson",
        "StateID": "MO",
        "url": "ferguson-mo"
    },
    {
        "State": "Missouri",
        "City": "Rolla",
        "StateID": "MO",
        "url": "rolla-mo"
    },
    {
        "State": "Missouri",
        "City": "Hannibal",
        "StateID": "MO",
        "url": "hannibal-mo"
    },
    {
        "State": "Missouri",
        "City": "Washington",
        "StateID": "MO",
        "url": "washington-mo"
    },
    {
        "State": "Missouri",
        "City": "Poplar Bluff",
        "StateID": "MO",
        "url": "poplarbluff-mo"
    },
    {
        "State": "Missouri",
        "City": "Grain Valley",
        "StateID": "MO",
        "url": "grainvalley-mo"
    },
    {
        "State": "Missouri",
        "City": "Creve Coeur",
        "StateID": "MO",
        "url": "crevecoeur-mo"
    },
    {
        "State": "Missouri",
        "City": "Oakville",
        "StateID": "MO",
        "url": "oakville-mo"
    },
    {
        "State": "Missouri",
        "City": "Kearney",
        "StateID": "MO",
        "url": "kearney-mo"
    },
    {
        "State": "Missouri",
        "City": "Lake St. Louis",
        "StateID": "MO",
        "url": "lakest.louis-mo"
    },
    {
        "State": "Missouri",
        "City": "Carthage",
        "StateID": "MO",
        "url": "carthage-mo"
    },
    {
        "State": "Missouri",
        "City": "Republic",
        "StateID": "MO",
        "url": "republic-mo"
    },
    {
        "State": "Missouri",
        "City": "Neosho",
        "StateID": "MO",
        "url": "neosho-mo"
    },
    {
        "State": "Missouri",
        "City": "Troy",
        "StateID": "MO",
        "url": "troy-mo"
    },
    {
        "State": "Missouri",
        "City": "Osage Beach",
        "StateID": "MO",
        "url": "osagebeach-mo"
    },
    {
        "State": "Missouri",
        "City": "Bolivar",
        "StateID": "MO",
        "url": "bolivar-mo"
    },
    {
        "State": "Missouri",
        "City": "Pacific",
        "StateID": "MO",
        "url": "pacific-mo"
    },
    {
        "State": "Missouri",
        "City": "Sikeston",
        "StateID": "MO",
        "url": "sikeston-mo"
    },
    {
        "State": "Missouri",
        "City": "Branson",
        "StateID": "MO",
        "url": "branson-mo"
    },
    {
        "State": "Missouri",
        "City": "Moberly",
        "StateID": "MO",
        "url": "moberly-mo"
    },
    {
        "State": "Missouri",
        "City": "Smithville",
        "StateID": "MO",
        "url": "smithville-mo"
    },
    {
        "State": "Missouri",
        "City": "Marshall",
        "StateID": "MO",
        "url": "marshall-mo"
    },
    {
        "State": "Missouri",
        "City": "Jennings",
        "StateID": "MO",
        "url": "jennings-mo"
    },
    {
        "State": "Missouri",
        "City": "Cameron",
        "StateID": "MO",
        "url": "cameron-mo"
    },
    {
        "State": "Missouri",
        "City": "Mexico",
        "StateID": "MO",
        "url": "mexico-mo"
    },
    {
        "State": "Missouri",
        "City": "Harrisonville",
        "StateID": "MO",
        "url": "harrisonville-mo"
    },
    {
        "State": "Missouri",
        "City": "Dexter",
        "StateID": "MO",
        "url": "dexter-mo"
    },
    {
        "State": "Missouri",
        "City": "Farmington",
        "StateID": "MO",
        "url": "farmington-mo"
    },
    {
        "State": "Missouri",
        "City": "Fulton",
        "StateID": "MO",
        "url": "fulton-mo"
    },
    {
        "State": "Missouri",
        "City": "Kennett",
        "StateID": "MO",
        "url": "kennett-mo"
    },
    {
        "State": "Missouri",
        "City": "Monett",
        "StateID": "MO",
        "url": "monett-mo"
    },
    {
        "State": "Missouri",
        "City": "Savannah",
        "StateID": "MO",
        "url": "savannah-mo"
    },
    {
        "State": "Missouri",
        "City": "Macon",
        "StateID": "MO",
        "url": "macon-mo"
    },
    {
        "State": "Missouri",
        "City": "Sullivan",
        "StateID": "MO",
        "url": "sullivan-mo"
    },
    {
        "State": "Missouri",
        "City": "Trenton",
        "StateID": "MO",
        "url": "trenton-mo"
    },
    {
        "State": "Missouri",
        "City": "Eldon",
        "StateID": "MO",
        "url": "eldon-mo"
    },
    {
        "State": "Missouri",
        "City": "Wright City",
        "StateID": "MO",
        "url": "wrightcity-mo"
    },
    {
        "State": "Missouri",
        "City": "Bonne Terre",
        "StateID": "MO",
        "url": "bonneterre-mo"
    },
    {
        "State": "Missouri",
        "City": "Potosi",
        "StateID": "MO",
        "url": "potosi-mo"
    },
    {
        "State": "Missouri",
        "City": "Maryville",
        "StateID": "MO",
        "url": "maryville-mo"
    },
    {
        "State": "Missouri",
        "City": "Forsyth",
        "StateID": "MO",
        "url": "forsyth-mo"
    },
    {
        "State": "Missouri",
        "City": "Hillsboro",
        "StateID": "MO",
        "url": "hillsboro-mo"
    },
    {
        "State": "Missouri",
        "City": "Berkeley",
        "StateID": "MO",
        "url": "berkeley-mo"
    },
    {
        "State": "Missouri",
        "City": "Perryville",
        "StateID": "MO",
        "url": "perryville-mo"
    },
    {
        "State": "Missouri",
        "City": "Versailles",
        "StateID": "MO",
        "url": "versailles-mo"
    },
    {
        "State": "Missouri",
        "City": "Steelville",
        "StateID": "MO",
        "url": "steelville-mo"
    },
    {
        "State": "Missouri",
        "City": "Warsaw",
        "StateID": "MO",
        "url": "warsaw-mo"
    },
    {
        "State": "Missouri",
        "City": "Park Hills",
        "StateID": "MO",
        "url": "parkhills-mo"
    },
    {
        "State": "Missouri",
        "City": "Louisiana",
        "StateID": "MO",
        "url": "louisiana-mo"
    },
    {
        "State": "Missouri",
        "City": "Tipton",
        "StateID": "MO",
        "url": "tipton-mo"
    },
    {
        "State": "Missouri",
        "City": "Windsor",
        "StateID": "MO",
        "url": "windsor-mo"
    },
    {
        "State": "Missouri",
        "City": "Lathrop",
        "StateID": "MO",
        "url": "lathrop-mo"
    },
    {
        "State": "Missouri",
        "City": "Pevely",
        "StateID": "MO",
        "url": "pevely-mo"
    },
    {
        "State": "Missouri",
        "City": "Charleston",
        "StateID": "MO",
        "url": "charleston-mo"
    },
    {
        "State": "Missouri",
        "City": "Crane",
        "StateID": "MO",
        "url": "crane-mo"
    },
    {
        "State": "Missouri",
        "City": "Noel",
        "StateID": "MO",
        "url": "noel-mo"
    },
    {
        "State": "Missouri",
        "City": "Hollister",
        "StateID": "MO",
        "url": "hollister-mo"
    },
    {
        "State": "Missouri",
        "City": "Forsyth",
        "StateID": "MO",
        "url": "forsyth-mo"
    },
    {
        "State": "Missouri",
        "City": "Bethany",
        "StateID": "MO",
        "url": "bethany-mo"
    },
    {
        "State": "Missouri",
        "City": "Desloge",
        "StateID": "MO",
        "url": "desloge-mo"
    },
    {
        "State": "Missouri",
        "City": "Parkville",
        "StateID": "MO",
        "url": "parkville-mo"
    },
    {
        "State": "Missouri",
        "City": "Seneca",
        "StateID": "MO",
        "url": "seneca-mo"
    },
    {
        "State": "Missouri",
        "City": "Salem",
        "StateID": "MO",
        "url": "salem-mo"
    },
    {
        "State": "Missouri",
        "City": "Bowling Green",
        "StateID": "MO",
        "url": "bowlinggreen-mo"
    },
    {
        "State": "Missouri",
        "City": "Malden",
        "StateID": "MO",
        "url": "malden-mo"
    },
    {
        "State": "Missouri",
        "City": "Pierce City",
        "StateID": "MO",
        "url": "piercecity-mo"
    },
    {
        "State": "Missouri",
        "City": "Richland",
        "StateID": "MO",
        "url": "richland-mo"
    },
    {
        "State": "Connecticut",
        "City": "Bridgeport",
        "StateID": "CT",
        "url": "bridgeport-ct"
    },
    {
        "State": "Connecticut",
        "City": "New Haven",
        "StateID": "CT",
        "url": "newhaven-ct"
    },
    {
        "State": "Connecticut",
        "City": "Stamford",
        "StateID": "CT",
        "url": "stamford-ct"
    },
    {
        "State": "Connecticut",
        "City": "Hartford",
        "StateID": "CT",
        "url": "hartford-ct"
    },
    {
        "State": "Connecticut",
        "City": "Waterbury",
        "StateID": "CT",
        "url": "waterbury-ct"
    },
    {
        "State": "Connecticut",
        "City": "Norwalk",
        "StateID": "CT",
        "url": "norwalk-ct"
    },
    {
        "State": "Connecticut",
        "City": "Danbury",
        "StateID": "CT",
        "url": "danbury-ct"
    },
    {
        "State": "Connecticut",
        "City": "New Britain",
        "StateID": "CT",
        "url": "newbritain-ct"
    },
    {
        "State": "Connecticut",
        "City": "Bristol",
        "StateID": "CT",
        "url": "bristol-ct"
    },
    {
        "State": "Connecticut",
        "City": "Meriden",
        "StateID": "CT",
        "url": "meriden-ct"
    },
    {
        "State": "Connecticut",
        "City": "West Haven",
        "StateID": "CT",
        "url": "westhaven-ct"
    },
    {
        "State": "Connecticut",
        "City": "Milford",
        "StateID": "CT",
        "url": "milford-ct"
    },
    {
        "State": "Connecticut",
        "City": "Middletown",
        "StateID": "CT",
        "url": "middletown-ct"
    },
    {
        "State": "Connecticut",
        "City": "Shelton",
        "StateID": "CT",
        "url": "shelton-ct"
    },
    {
        "State": "Connecticut",
        "City": "Norwich",
        "StateID": "CT",
        "url": "norwich-ct"
    },
    {
        "State": "Connecticut",
        "City": "Torrington",
        "StateID": "CT",
        "url": "torrington-ct"
    },
    {
        "State": "Connecticut",
        "City": "Naugatuck",
        "StateID": "CT",
        "url": "naugatuck-ct"
    },
    {
        "State": "Connecticut",
        "City": "New London",
        "StateID": "CT",
        "url": "newlondon-ct"
    },
    {
        "State": "Connecticut",
        "City": "Ansonia",
        "StateID": "CT",
        "url": "ansonia-ct"
    },
    {
        "State": "Connecticut",
        "City": "Derby",
        "StateID": "CT",
        "url": "derby-ct"
    },
    {
        "State": "Connecticut",
        "City": "Groton",
        "StateID": "CT",
        "url": "groton-ct"
    },
    {
        "State": "Connecticut",
        "City": "Wethersfield",
        "StateID": "CT",
        "url": "wethersfield-ct"
    },
    {
        "State": "Connecticut",
        "City": "Windham",
        "StateID": "CT",
        "url": "windham-ct"
    },
    {
        "State": "Connecticut",
        "City": "Farmington",
        "StateID": "CT",
        "url": "farmington-ct"
    },
    {
        "State": "Connecticut",
        "City": "Simsbury",
        "StateID": "CT",
        "url": "simsbury-ct"
    },
    {
        "State": "Connecticut",
        "City": "Wallingford",
        "StateID": "CT",
        "url": "wallingford-ct"
    },
    {
        "State": "Connecticut",
        "City": "Enfield",
        "StateID": "CT",
        "url": "enfield-ct"
    },
    {
        "State": "Connecticut",
        "City": "Cheshire",
        "StateID": "CT",
        "url": "cheshire-ct"
    },
    {
        "State": "Connecticut",
        "City": "Guilford",
        "StateID": "CT",
        "url": "guilford-ct"
    },
    {
        "State": "Connecticut",
        "City": "Branford",
        "StateID": "CT",
        "url": "branford-ct"
    },
    {
        "State": "Connecticut",
        "City": "Glastonbury",
        "StateID": "CT",
        "url": "glastonbury-ct"
    },
    {
        "State": "Connecticut",
        "City": "Southington",
        "StateID": "CT",
        "url": "southington-ct"
    },
    {
        "State": "Connecticut",
        "City": "Rocky Hill",
        "StateID": "CT",
        "url": "rockyhill-ct"
    },
    {
        "State": "Connecticut",
        "City": "Ledyard",
        "StateID": "CT",
        "url": "ledyard-ct"
    },
    {
        "State": "Connecticut",
        "City": "Berlin",
        "StateID": "CT",
        "url": "berlin-ct"
    },
    {
        "State": "Connecticut",
        "City": "Ridgefield",
        "StateID": "CT",
        "url": "ridgefield-ct"
    },
    {
        "State": "Connecticut",
        "City": "New Canaan",
        "StateID": "CT",
        "url": "newcanaan-ct"
    },
    {
        "State": "Connecticut",
        "City": "East Haven",
        "StateID": "CT",
        "url": "easthaven-ct"
    },
    {
        "State": "Connecticut",
        "City": "Greenwich",
        "StateID": "CT",
        "url": "greenwich-ct"
    },
    {
        "State": "Connecticut",
        "City": "Hamden",
        "StateID": "CT",
        "url": "hamden-ct"
    },
    {
        "State": "Connecticut",
        "City": "Shelton",
        "StateID": "CT",
        "url": "shelton-ct"
    },
    {
        "State": "Connecticut",
        "City": "South Windsor",
        "StateID": "CT",
        "url": "southwindsor-ct"
    },
    {
        "State": "Connecticut",
        "City": "Trumbull",
        "StateID": "CT",
        "url": "trumbull-ct"
    },
    {
        "State": "Connecticut",
        "City": "Wilton",
        "StateID": "CT",
        "url": "wilton-ct"
    },
    {
        "State": "Connecticut",
        "City": "Bethel",
        "StateID": "CT",
        "url": "bethel-ct"
    },
    {
        "State": "Connecticut",
        "City": "Old Saybrook",
        "StateID": "CT",
        "url": "oldsaybrook-ct"
    },
    {
        "State": "Connecticut",
        "City": "Newington",
        "StateID": "CT",
        "url": "newington-ct"
    },
    {
        "State": "Connecticut",
        "City": "East Hartford",
        "StateID": "CT",
        "url": "easthartford-ct"
    },
    {
        "State": "Connecticut",
        "City": "North Haven",
        "StateID": "CT",
        "url": "northhaven-ct"
    },
    {
        "State": "Connecticut",
        "City": "Stonington",
        "StateID": "CT",
        "url": "stonington-ct"
    },
    {
        "State": "Connecticut",
        "City": "Madison",
        "StateID": "CT",
        "url": "madison-ct"
    },
    {
        "State": "Connecticut",
        "City": "Avon",
        "StateID": "CT",
        "url": "avon-ct"
    },
    {
        "State": "Connecticut",
        "City": "Waterford",
        "StateID": "CT",
        "url": "waterford-ct"
    },
    {
        "State": "Connecticut",
        "City": "Bloomfield",
        "StateID": "CT",
        "url": "bloomfield-ct"
    },
    {
        "State": "Connecticut",
        "City": "Stratford",
        "StateID": "CT",
        "url": "stratford-ct"
    },
    {
        "State": "Connecticut",
        "City": "Darien",
        "StateID": "CT",
        "url": "darien-ct"
    },
    {
        "State": "Connecticut",
        "City": "Redding",
        "StateID": "CT",
        "url": "redding-ct"
    },
    {
        "State": "Connecticut",
        "City": "Monroe",
        "StateID": "CT",
        "url": "monroe-ct"
    },
    {
        "State": "Connecticut",
        "City": "East Lyme",
        "StateID": "CT",
        "url": "eastlyme-ct"
    },
    {
        "State": "Connecticut",
        "City": "Windsor",
        "StateID": "CT",
        "url": "windsor-ct"
    },
    {
        "State": "Connecticut",
        "City": "Tolland",
        "StateID": "CT",
        "url": "tolland-ct"
    },
    {
        "State": "Connecticut",
        "City": "Vernon",
        "StateID": "CT",
        "url": "vernon-ct"
    },
    {
        "State": "Connecticut",
        "City": "Granby",
        "StateID": "CT",
        "url": "granby-ct"
    },
    {
        "State": "Connecticut",
        "City": "Essex",
        "StateID": "CT",
        "url": "essex-ct"
    },
    {
        "State": "Connecticut",
        "City": "Canton",
        "StateID": "CT",
        "url": "canton-ct"
    },
    {
        "State": "Connecticut",
        "City": "Plainville",
        "StateID": "CT",
        "url": "plainville-ct"
    },
    {
        "State": "Connecticut",
        "City": "Old Lyme",
        "StateID": "CT",
        "url": "oldlyme-ct"
    },
    {
        "State": "Connecticut",
        "City": "New Fairfield",
        "StateID": "CT",
        "url": "newfairfield-ct"
    },
    {
        "State": "Connecticut",
        "City": "Westport",
        "StateID": "CT",
        "url": "westport-ct"
    },
    {
        "State": "Connecticut",
        "City": "Clinton",
        "StateID": "CT",
        "url": "clinton-ct"
    },
    {
        "State": "Colorado",
        "City": "Denver",
        "StateID": "CO",
        "url": "denver-co"
    },
    {
        "State": "Colorado",
        "City": "Colorado Springs",
        "StateID": "CO",
        "url": "coloradosprings-co"
    },
    {
        "State": "Colorado",
        "City": "Aurora",
        "StateID": "CO",
        "url": "aurora-co"
    },
    {
        "State": "Colorado",
        "City": "Fort Collins",
        "StateID": "CO",
        "url": "fortcollins-co"
    },
    {
        "State": "Colorado",
        "City": "Lakewood",
        "StateID": "CO",
        "url": "lakewood-co"
    },
    {
        "State": "Colorado",
        "City": "Thornton",
        "StateID": "CO",
        "url": "thornton-co"
    },
    {
        "State": "Colorado",
        "City": "Arvada",
        "StateID": "CO",
        "url": "arvada-co"
    },
    {
        "State": "Colorado",
        "City": "Westminster",
        "StateID": "CO",
        "url": "westminster-co"
    },
    {
        "State": "Colorado",
        "City": "Pueblo",
        "StateID": "CO",
        "url": "pueblo-co"
    },
    {
        "State": "Colorado",
        "City": "Greeley",
        "StateID": "CO",
        "url": "greeley-co"
    },
    {
        "State": "Colorado",
        "City": "Centennial",
        "StateID": "CO",
        "url": "centennial-co"
    },
    {
        "State": "Colorado",
        "City": "Boulder",
        "StateID": "CO",
        "url": "boulder-co"
    },
    {
        "State": "Colorado",
        "City": "Longmont",
        "StateID": "CO",
        "url": "longmont-co"
    },
    {
        "State": "Colorado",
        "City": "Loveland",
        "StateID": "CO",
        "url": "loveland-co"
    },
    {
        "State": "Colorado",
        "City": "Broomfield",
        "StateID": "CO",
        "url": "broomfield-co"
    },
    {
        "State": "Colorado",
        "City": "Grand Junction",
        "StateID": "CO",
        "url": "grandjunction-co"
    },
    {
        "State": "Colorado",
        "City": "Castle Rock",
        "StateID": "CO",
        "url": "castlerock-co"
    },
    {
        "State": "Colorado",
        "City": "Commerce City",
        "StateID": "CO",
        "url": "commercecity-co"
    },
    {
        "State": "Colorado",
        "City": "Parker",
        "StateID": "CO",
        "url": "parker-co"
    },
    {
        "State": "Colorado",
        "City": "Littleton",
        "StateID": "CO",
        "url": "littleton-co"
    },
    {
        "State": "Colorado",
        "City": "Northglenn",
        "StateID": "CO",
        "url": "northglenn-co"
    },
    {
        "State": "Colorado",
        "City": "Brighton",
        "StateID": "CO",
        "url": "brighton-co"
    },
    {
        "State": "Colorado",
        "City": "Englewood",
        "StateID": "CO",
        "url": "englewood-co"
    },
    {
        "State": "Colorado",
        "City": "Wheat Ridge",
        "StateID": "CO",
        "url": "wheatridge-co"
    },
    {
        "State": "Colorado",
        "City": "Fountain",
        "StateID": "CO",
        "url": "fountain-co"
    },
    {
        "State": "Colorado",
        "City": "Lafayette",
        "StateID": "CO",
        "url": "lafayette-co"
    },
    {
        "State": "Colorado",
        "City": "Windsor",
        "StateID": "CO",
        "url": "windsor-co"
    },
    {
        "State": "Colorado",
        "City": "Erie",
        "StateID": "CO",
        "url": "erie-co"
    },
    {
        "State": "Colorado",
        "City": "Evans",
        "StateID": "CO",
        "url": "evans-co"
    },
    {
        "State": "Colorado",
        "City": "Golden",
        "StateID": "CO",
        "url": "golden-co"
    },
    {
        "State": "Colorado",
        "City": "Louisville",
        "StateID": "CO",
        "url": "louisville-co"
    },
    {
        "State": "Colorado",
        "City": "Durango",
        "StateID": "CO",
        "url": "durango-co"
    },
    {
        "State": "Colorado",
        "City": "Montrose",
        "StateID": "CO",
        "url": "montrose-co"
    },
    {
        "State": "Colorado",
        "City": "Glenwood Springs",
        "StateID": "CO",
        "url": "glenwoodsprings-co"
    },
    {
        "State": "Colorado",
        "City": "Firestone",
        "StateID": "CO",
        "url": "firestone-co"
    },
    {
        "State": "Colorado",
        "City": "Canon City",
        "StateID": "CO",
        "url": "canoncity-co"
    },
    {
        "State": "Colorado",
        "City": "Federal Heights",
        "StateID": "CO",
        "url": "federalheights-co"
    },
    {
        "State": "Colorado",
        "City": "Fruita",
        "StateID": "CO",
        "url": "fruita-co"
    },
    {
        "State": "Colorado",
        "City": "Sterling",
        "StateID": "CO",
        "url": "sterling-co"
    },
    {
        "State": "Colorado",
        "City": "Alamosa",
        "StateID": "CO",
        "url": "alamosa-co"
    },
    {
        "State": "Colorado",
        "City": "Cortez",
        "StateID": "CO",
        "url": "cortez-co"
    },
    {
        "State": "Colorado",
        "City": "Woodland Park",
        "StateID": "CO",
        "url": "woodlandpark-co"
    },
    {
        "State": "Colorado",
        "City": "Johnstown",
        "StateID": "CO",
        "url": "johnstown-co"
    },
    {
        "State": "Colorado",
        "City": "Fort Mcoman",
        "StateID": "CO",
        "url": "fortmcoman-co"
    },
    {
        "State": "Colorado",
        "City": "Lone Tree",
        "StateID": "CO",
        "url": "lonetree-co"
    },
    {
        "State": "Colorado",
        "City": "La Junta",
        "StateID": "CO",
        "url": "lajunta-co"
    },
    {
        "State": "Colorado",
        "City": "Delta",
        "StateID": "CO",
        "url": "delta-co"
    },
    {
        "State": "Colorado",
        "City": "Estes Park",
        "StateID": "CO",
        "url": "estespark-co"
    },
    {
        "State": "Colorado",
        "City": "Wellington",
        "StateID": "CO",
        "url": "wellington-co"
    },
    {
        "State": "Colorado",
        "City": "Mead",
        "StateID": "CO",
        "url": "mead-co"
    },
    {
        "State": "Colorado",
        "City": "Carbondale",
        "StateID": "CO",
        "url": "carbondale-co"
    },
    {
        "State": "Colorado",
        "City": "Frederick",
        "StateID": "CO",
        "url": "frederick-co"
    },
    {
        "State": "Colorado",
        "City": "Dacono",
        "StateID": "CO",
        "url": "dacono-co"
    },
    {
        "State": "Colorado",
        "City": "Berthoud",
        "StateID": "CO",
        "url": "berthoud-co"
    },
    {
        "State": "Colorado",
        "City": "Gypsum",
        "StateID": "CO",
        "url": "gypsum-co"
    },
    {
        "State": "Colorado",
        "City": "Eagle",
        "StateID": "CO",
        "url": "eagle-co"
    },
    {
        "State": "Colorado",
        "City": "Buena Vista",
        "StateID": "CO",
        "url": "buenavista-co"
    },
    {
        "State": "Colorado",
        "City": "Woodland Park",
        "StateID": "CO",
        "url": "woodlandpark-co"
    },
    {
        "State": "Colorado",
        "City": "Basalt",
        "StateID": "CO",
        "url": "basalt-co"
    },
    {
        "State": "Colorado",
        "City": "Brush",
        "StateID": "CO",
        "url": "brush-co"
    },
    {
        "State": "Colorado",
        "City": "Craig",
        "StateID": "CO",
        "url": "craig-co"
    },
    {
        "State": "Colorado",
        "City": "Leadville",
        "StateID": "CO",
        "url": "leadville-co"
    },
    {
        "State": "Colorado",
        "City": "Lamar",
        "StateID": "CO",
        "url": "lamar-co"
    },
    {
        "State": "Colorado",
        "City": "Glenwood Springs",
        "StateID": "CO",
        "url": "glenwoodsprings-co"
    },
    {
        "State": "Colorado",
        "City": "Walsenburg",
        "StateID": "CO",
        "url": "walsenburg-co"
    },
    {
        "State": "Colorado",
        "City": "Gunnison",
        "StateID": "CO",
        "url": "gunnison-co"
    },
    {
        "State": "Colorado",
        "City": "Fairplay",
        "StateID": "CO",
        "url": "fairplay-co"
    },
    {
        "State": "Colorado",
        "City": "Pagosa Springs",
        "StateID": "CO",
        "url": "pagosasprings-co"
    },
    {
        "State": "Colorado",
        "City": "Bayfield",
        "StateID": "CO",
        "url": "bayfield-co"
    },
    {
        "State": "Colorado",
        "City": "Dolores",
        "StateID": "CO",
        "url": "dolores-co"
    },
    {
        "State": "Colorado",
        "City": "Timnath",
        "StateID": "CO",
        "url": "timnath-co"
    },
    {
        "State": "Colorado",
        "City": "New Castle",
        "StateID": "CO",
        "url": "newcastle-co"
    },
    {
        "State": "Colorado",
        "City": "Aspen",
        "StateID": "CO",
        "url": "aspen-co"
    },
    {
        "State": "Colorado",
        "City": "Manitou Springs",
        "StateID": "CO",
        "url": "manitousprings-co"
    },
    {
        "State": "Colorado",
        "City": "Gecometown",
        "StateID": "CO",
        "url": "gecometown-co"
    },
    {
        "State": "Colorado",
        "City": "Rifle",
        "StateID": "CO",
        "url": "rifle-co"
    },
    {
        "State": "Colorado",
        "City": "Breckenridge",
        "StateID": "CO",
        "url": "breckenridge-co"
    },
    {
        "State": "Colorado",
        "City": "Salida",
        "StateID": "CO",
        "url": "salida-co"
    },
    {
        "State": "Colorado",
        "City": "Lyons",
        "StateID": "CO",
        "url": "lyons-co"
    },
    {
        "State": "Colorado",
        "City": "Yuma",
        "StateID": "CO",
        "url": "yuma-co"
    },
    {
        "State": "Colorado",
        "City": "Monument",
        "StateID": "CO",
        "url": "monument-co"
    },
    {
        "State": "Colorado",
        "City": "Larkspur",
        "StateID": "CO",
        "url": "larkspur-co"
    },
    {
        "State": "Colorado",
        "City": "Nederland",
        "StateID": "CO",
        "url": "nederland-co"
    },
    {
        "State": "Colorado",
        "City": "Wray",
        "StateID": "CO",
        "url": "wray-co"
    },
    {
        "State": "Colorado",
        "City": "Bennett",
        "StateID": "CO",
        "url": "bennett-co"
    },
    {
        "State": "Colorado",
        "City": "Elizabeth",
        "StateID": "CO",
        "url": "elizabeth-co"
    },
    {
        "State": "Colorado",
        "City": "Akron",
        "StateID": "CO",
        "url": "akron-co"
    },
    {
        "State": "Colorado",
        "City": "Burlington",
        "StateID": "CO",
        "url": "burlington-co"
    },
    {
        "State": "Colorado",
        "City": "Fowler",
        "StateID": "CO",
        "url": "fowler-co"
    },
    {
        "State": "Colorado",
        "City": "Hayden",
        "StateID": "CO",
        "url": "hayden-co"
    },
    {
        "State": "Colorado",
        "City": "Parachute",
        "StateID": "CO",
        "url": "parachute-co"
    },
    {
        "State": "Colorado",
        "City": "Silt",
        "StateID": "CO",
        "url": "silt-co"
    },
    {
        "State": "Colorado",
        "City": "Morrison",
        "StateID": "CO",
        "url": "morrison-co"
    },
    {
        "State": "Colorado",
        "City": "Mancos",
        "StateID": "CO",
        "url": "mancos-co"
    },
    {
        "State": "Colorado",
        "City": "Crested Butte",
        "StateID": "CO",
        "url": "crestedbutte-co"
    },
    {
        "State": "Colorado",
        "City": "Red Cliff",
        "StateID": "CO",
        "url": "redcliff-co"
    },
    {
        "State": "Colorado",
        "City": "Rangely",
        "StateID": "CO",
        "url": "rangely-co"
    },
    {
        "State": "Colorado",
        "City": "Kremmling",
        "StateID": "CO",
        "url": "kremmling-co"
    },
    {
        "State": "Colorado",
        "City": "Silverthorne",
        "StateID": "CO",
        "url": "silverthorne-co"
    },
    {
        "State": "Colorado",
        "City": "Idaho Springs",
        "StateID": "CO",
        "url": "idahosprings-co"
    },
    {
        "State": "New York",
        "City": "New York City",
        "StateID": "NY",
        "url": "newyorkcity-ny"
    },
    {
        "State": "New York",
        "City": "Buffalo",
        "StateID": "NY",
        "url": "buffalo-ny"
    },
    {
        "State": "New York",
        "City": "Rochester",
        "StateID": "NY",
        "url": "rochester-ny"
    },
    {
        "State": "New York",
        "City": "Yonkers",
        "StateID": "NY",
        "url": "yonkers-ny"
    },
    {
        "State": "New York",
        "City": "Syracuse",
        "StateID": "NY",
        "url": "syracuse-ny"
    },
    {
        "State": "New York",
        "City": "Albany",
        "StateID": "NY",
        "url": "albany-ny"
    },
    {
        "State": "New York",
        "City": "New Rochelle",
        "StateID": "NY",
        "url": "newrochelle-ny"
    },
    {
        "State": "New York",
        "City": "Mount Vernon",
        "StateID": "NY",
        "url": "mountvernon-ny"
    },
    {
        "State": "New York",
        "City": "Schenectady",
        "StateID": "NY",
        "url": "schenectady-ny"
    },
    {
        "State": "New York",
        "City": "Utica",
        "StateID": "NY",
        "url": "utica-ny"
    },
    {
        "State": "New York",
        "City": "White Plains",
        "StateID": "NY",
        "url": "whiteplains-ny"
    },
    {
        "State": "New York",
        "City": "Hempstead",
        "StateID": "NY",
        "url": "hempstead-ny"
    },
    {
        "State": "New York",
        "City": "Troy",
        "StateID": "NY",
        "url": "troy-ny"
    },
    {
        "State": "New York",
        "City": "Niagara Falls",
        "StateID": "NY",
        "url": "niagarafalls-ny"
    },
    {
        "State": "New York",
        "City": "Binghamton",
        "StateID": "NY",
        "url": "binghamton-ny"
    },
    {
        "State": "New York",
        "City": "Freeport",
        "StateID": "NY",
        "url": "freeport-ny"
    },
    {
        "State": "New York",
        "City": "Valley Stream",
        "StateID": "NY",
        "url": "valleystream-ny"
    },
    {
        "State": "New York",
        "City": "Long Beach",
        "StateID": "NY",
        "url": "longbeach-ny"
    },
    {
        "State": "New York",
        "City": "Rome",
        "StateID": "NY",
        "url": "rome-ny"
    },
    {
        "State": "New York",
        "City": "Ithaca",
        "StateID": "NY",
        "url": "ithaca-ny"
    },
    {
        "State": "New York",
        "City": "Poughkeepsie",
        "StateID": "NY",
        "url": "poughkeepsie-ny"
    },
    {
        "State": "New York",
        "City": "North Tonawanda",
        "StateID": "NY",
        "url": "northtonawanda-ny"
    },
    {
        "State": "New York",
        "City": "Jamestown",
        "StateID": "NY",
        "url": "jamestown-ny"
    },
    {
        "State": "New York",
        "City": "Elmira",
        "StateID": "NY",
        "url": "elmira-ny"
    },
    {
        "State": "New York",
        "City": "Saratoga Springs",
        "StateID": "NY",
        "url": "saratogasprings-ny"
    },
    {
        "State": "New York",
        "City": "Middletown",
        "StateID": "NY",
        "url": "middletown-ny"
    },
    {
        "State": "New York",
        "City": "Auburn",
        "StateID": "NY",
        "url": "auburn-ny"
    },
    {
        "State": "New York",
        "City": "Newburgh",
        "StateID": "NY",
        "url": "newburgh-ny"
    },
    {
        "State": "New York",
        "City": "Peekskill",
        "StateID": "NY",
        "url": "peekskill-ny"
    },
    {
        "State": "New York",
        "City": "Lockport",
        "StateID": "NY",
        "url": "lockport-ny"
    },
    {
        "State": "New York",
        "City": "Cortland",
        "StateID": "NY",
        "url": "cortland-ny"
    },
    {
        "State": "New York",
        "City": "Plattsburgh",
        "StateID": "NY",
        "url": "plattsburgh-ny"
    },
    {
        "State": "New York",
        "City": "Watertown",
        "StateID": "NY",
        "url": "watertown-ny"
    },
    {
        "State": "New York",
        "City": "Glen Cove",
        "StateID": "NY",
        "url": "glencove-ny"
    },
    {
        "State": "New York",
        "City": "Kingston",
        "StateID": "NY",
        "url": "kingston-ny"
    },
    {
        "State": "New York",
        "City": "Batavia",
        "StateID": "NY",
        "url": "batavia-ny"
    },
    {
        "State": "New York",
        "City": "Amsterdam",
        "StateID": "NY",
        "url": "amsterdam-ny"
    },
    {
        "State": "New York",
        "City": "Oswego",
        "StateID": "NY",
        "url": "oswego-ny"
    },
    {
        "State": "New York",
        "City": "Geneva",
        "StateID": "NY",
        "url": "geneva-ny"
    },
    {
        "State": "New York",
        "City": "Lackawanna",
        "StateID": "NY",
        "url": "lackawanna-ny"
    },
    {
        "State": "New York",
        "City": "Beacon",
        "StateID": "NY",
        "url": "beacon-ny"
    },
    {
        "State": "New York",
        "City": "Fulton",
        "StateID": "NY",
        "url": "fulton-ny"
    },
    {
        "State": "New York",
        "City": "Rye",
        "StateID": "NY",
        "url": "rye-ny"
    },
    {
        "State": "New York",
        "City": "Oneida",
        "StateID": "NY",
        "url": "oneida-ny"
    },
    {
        "State": "New York",
        "City": "Glens Falls",
        "StateID": "NY",
        "url": "glensfalls-ny"
    },
    {
        "State": "New York",
        "City": "Olean",
        "StateID": "NY",
        "url": "olean-ny"
    },
    {
        "State": "New York",
        "City": "Tonawanda",
        "StateID": "NY",
        "url": "tonawanda-ny"
    },
    {
        "State": "New York",
        "City": "Watervliet",
        "StateID": "NY",
        "url": "watervliet-ny"
    },
    {
        "State": "New York",
        "City": "Canandaigua",
        "StateID": "NY",
        "url": "canandaigua-ny"
    },
    {
        "State": "New York",
        "City": "Cohoes",
        "StateID": "NY",
        "url": "cohoes-ny"
    },
    {
        "State": "New York",
        "City": "Dunkirk",
        "StateID": "NY",
        "url": "dunkirk-ny"
    },
    {
        "State": "New York",
        "City": "Rensselaer",
        "StateID": "NY",
        "url": "rensselaer-ny"
    },
    {
        "State": "New York",
        "City": "Oneonta",
        "StateID": "NY",
        "url": "oneonta-ny"
    },
    {
        "State": "New York",
        "City": "Corning",
        "StateID": "NY",
        "url": "corning-ny"
    },
    {
        "State": "New York",
        "City": "Hornell",
        "StateID": "NY",
        "url": "hornell-ny"
    },
    {
        "State": "New York",
        "City": "Johnstown",
        "StateID": "NY",
        "url": "johnstown-ny"
    },
    {
        "State": "New York",
        "City": "Little Falls",
        "StateID": "NY",
        "url": "littlefalls-ny"
    },
    {
        "State": "New York",
        "City": "Hudson",
        "StateID": "NY",
        "url": "hudson-ny"
    },
    {
        "State": "New York",
        "City": "Mechanicville",
        "StateID": "NY",
        "url": "mechanicville-ny"
    },
    {
        "State": "New York",
        "City": "Norwich",
        "StateID": "NY",
        "url": "norwich-ny"
    },
    {
        "State": "New York",
        "City": "Ogdensburg",
        "StateID": "NY",
        "url": "ogdensburg-ny"
    },
    {
        "State": "New York",
        "City": "Salamanca",
        "StateID": "NY",
        "url": "salamanca-ny"
    },
    {
        "State": "New York",
        "City": "Sherrill",
        "StateID": "NY",
        "url": "sherrill-ny"
    },
    {
        "State": "New York",
        "City": "Port Jervis",
        "StateID": "NY",
        "url": "portjervis-ny"
    },
    {
        "State": "New York",
        "City": "Gloversville",
        "StateID": "NY",
        "url": "gloversville-ny"
    },
    {
        "State": "New York",
        "City": "Tarrytown",
        "StateID": "NY",
        "url": "tarrytown-ny"
    },
    {
        "State": "New York",
        "City": "Babylon",
        "StateID": "NY",
        "url": "babylon-ny"
    },
    {
        "State": "New York",
        "City": "Amityville",
        "StateID": "NY",
        "url": "amityville-ny"
    },
    {
        "State": "New York",
        "City": "Massapequa Park",
        "StateID": "NY",
        "url": "massapequapark-ny"
    },
    {
        "State": "New York",
        "City": "Floral Park",
        "StateID": "NY",
        "url": "floralpark-ny"
    },
    {
        "State": "New York",
        "City": "East Rockaway",
        "StateID": "NY",
        "url": "eastrockaway-ny"
    },
    {
        "State": "New York",
        "City": "Lynbrook",
        "StateID": "NY",
        "url": "lynbrook-ny"
    },
    {
        "State": "New York",
        "City": "Great Neck Plaza",
        "StateID": "NY",
        "url": "greatneckplaza-ny"
    },
    {
        "State": "New York",
        "City": "Malverne",
        "StateID": "NY",
        "url": "malverne-ny"
    },
    {
        "State": "New York",
        "City": "Patchogue",
        "StateID": "NY",
        "url": "patchogue-ny"
    },
    {
        "State": "New York",
        "City": "South Nyack",
        "StateID": "NY",
        "url": "southnyack-ny"
    },
    {
        "State": "New York",
        "City": "Ardsley",
        "StateID": "NY",
        "url": "ardsley-ny"
    },
    {
        "State": "New York",
        "City": "Hastings-on-Hudson",
        "StateID": "NY",
        "url": "hastings-on-hudson-ny"
    },
    {
        "State": "New York",
        "City": "Bronxville",
        "StateID": "NY",
        "url": "bronxville-ny"
    },
    {
        "State": "New York",
        "City": "Pelham Manor",
        "StateID": "NY",
        "url": "pelhammanor-ny"
    },
    {
        "State": "New York",
        "City": "Irvington",
        "StateID": "NY",
        "url": "irvington-ny"
    },
    {
        "State": "New York",
        "City": "Croton-on-Hudson",
        "StateID": "NY",
        "url": "croton-on-hudson-ny"
    },
    {
        "State": "New York",
        "City": "Dobbs Ferry",
        "StateID": "NY",
        "url": "dobbsferry-ny"
    },
    {
        "State": "New York",
        "City": "Tuckahoe",
        "StateID": "NY",
        "url": "tuckahoe-ny"
    },
    {
        "State": "New York",
        "City": "Northport",
        "StateID": "NY",
        "url": "northport-ny"
    },
    {
        "State": "New York",
        "City": "Sleepy Hollow",
        "StateID": "NY",
        "url": "sleepyhollow-ny"
    },
    {
        "State": "New York",
        "City": "Pleasantville",
        "StateID": "NY",
        "url": "pleasantville-ny"
    },
    {
        "State": "New York",
        "City": "Briarcliff Manor",
        "StateID": "NY",
        "url": "briarcliffmanor-ny"
    },
    {
        "State": "New York",
        "City": "Eastchester",
        "StateID": "NY",
        "url": "eastchester-ny"
    },
    {
        "State": "New York",
        "City": "Scarsdale",
        "StateID": "NY",
        "url": "scarsdale-ny"
    },
    {
        "State": "New York",
        "City": "Woodbury",
        "StateID": "NY",
        "url": "woodbury-ny"
    },
    {
        "State": "New York",
        "City": "Rockville Centre",
        "StateID": "NY",
        "url": "rockvillecentre-ny"
    },
    {
        "State": "New York",
        "City": "Bayville",
        "StateID": "NY",
        "url": "bayville-ny"
    },
    {
        "State": "New York",
        "City": "Old Westbury",
        "StateID": "NY",
        "url": "oldwestbury-ny"
    },
    {
        "State": "New York",
        "City": "Garden City",
        "StateID": "NY",
        "url": "gardencity-ny"
    },
    {
        "State": "New York",
        "City": "Cedarhurst",
        "StateID": "NY",
        "url": "cedarhurst-ny"
    },
    {
        "State": "New York",
        "City": "Lawrence",
        "StateID": "NY",
        "url": "lawrence-ny"
    },
    {
        "State": "New York",
        "City": "Port Washington",
        "StateID": "NY",
        "url": "portwashington-ny"
    },
    {
        "State": "New York",
        "City": "Mamaroneck",
        "StateID": "NY",
        "url": "mamaroneck-ny"
    },
    {
        "State": "New York",
        "City": "Manhasset",
        "StateID": "NY",
        "url": "manhasset-ny"
    },
    {
        "State": "Washington",
        "City": "Seattle",
        "StateID": "WA",
        "url": "seattle-wa"
    },
    {
        "State": "Washington",
        "City": "Spokane",
        "StateID": "WA",
        "url": "spokane-wa"
    },
    {
        "State": "Washington",
        "City": "Tacoma",
        "StateID": "WA",
        "url": "tacoma-wa"
    },
    {
        "State": "Washington",
        "City": "Vancouver",
        "StateID": "WA",
        "url": "vancouver-wa"
    },
    {
        "State": "Washington",
        "City": "Bellevue",
        "StateID": "WA",
        "url": "bellevue-wa"
    },
    {
        "State": "Washington",
        "City": "Kent",
        "StateID": "WA",
        "url": "kent-wa"
    },
    {
        "State": "Washington",
        "City": "Everett",
        "StateID": "WA",
        "url": "everett-wa"
    },
    {
        "State": "Washington",
        "City": "Renton",
        "StateID": "WA",
        "url": "renton-wa"
    },
    {
        "State": "Washington",
        "City": "Yakima",
        "StateID": "WA",
        "url": "yakima-wa"
    },
    {
        "State": "Washington",
        "City": "Federal Way",
        "StateID": "WA",
        "url": "federalway-wa"
    },
    {
        "State": "Washington",
        "City": "Spokane Valley",
        "StateID": "WA",
        "url": "spokanevalley-wa"
    },
    {
        "State": "Washington",
        "City": "Kirkland",
        "StateID": "WA",
        "url": "kirkland-wa"
    },
    {
        "State": "Washington",
        "City": "Bellingham",
        "StateID": "WA",
        "url": "bellingham-wa"
    },
    {
        "State": "Washington",
        "City": "Kennewick",
        "StateID": "WA",
        "url": "kennewick-wa"
    },
    {
        "State": "Washington",
        "City": "Auburn",
        "StateID": "WA",
        "url": "auburn-wa"
    },
    {
        "State": "Washington",
        "City": "Pasco",
        "StateID": "WA",
        "url": "pasco-wa"
    },
    {
        "State": "Washington",
        "City": "Marysville",
        "StateID": "WA",
        "url": "marysville-wa"
    },
    {
        "State": "Washington",
        "City": "Sammamish",
        "StateID": "WA",
        "url": "sammamish-wa"
    },
    {
        "State": "Washington",
        "City": "Lakewood",
        "StateID": "WA",
        "url": "lakewood-wa"
    },
    {
        "State": "Washington",
        "City": "Redmond",
        "StateID": "WA",
        "url": "redmond-wa"
    },
    {
        "State": "Washington",
        "City": "Shoreline",
        "StateID": "WA",
        "url": "shoreline-wa"
    },
    {
        "State": "Washington",
        "City": "Richland",
        "StateID": "WA",
        "url": "richland-wa"
    },
    {
        "State": "Washington",
        "City": "Burien",
        "StateID": "WA",
        "url": "burien-wa"
    },
    {
        "State": "Washington",
        "City": "Bremerton",
        "StateID": "WA",
        "url": "bremerton-wa"
    },
    {
        "State": "Washington",
        "City": "Puyallup",
        "StateID": "WA",
        "url": "puyallup-wa"
    },
    {
        "State": "Washington",
        "City": "Edmonds",
        "StateID": "WA",
        "url": "edmonds-wa"
    },
    {
        "State": "Washington",
        "City": "Lynnwood",
        "StateID": "WA",
        "url": "lynnwood-wa"
    },
    {
        "State": "Washington",
        "City": "Longview",
        "StateID": "WA",
        "url": "longview-wa"
    },
    {
        "State": "Washington",
        "City": "Lacey",
        "StateID": "WA",
        "url": "lacey-wa"
    },
    {
        "State": "Washington",
        "City": "Bothell",
        "StateID": "WA",
        "url": "bothell-wa"
    },
    {
        "State": "Washington",
        "City": "Issaquah",
        "StateID": "WA",
        "url": "issaquah-wa"
    },
    {
        "State": "Washington",
        "City": "Wenatchee",
        "StateID": "WA",
        "url": "wenatchee-wa"
    },
    {
        "State": "Washington",
        "City": "University Place",
        "StateID": "WA",
        "url": "universityplace-wa"
    },
    {
        "State": "Washington",
        "City": "Maple Valley",
        "StateID": "WA",
        "url": "maplevalley-wa"
    },
    {
        "State": "Washington",
        "City": "Mercer Island",
        "StateID": "WA",
        "url": "mercerisland-wa"
    },
    {
        "State": "Washington",
        "City": "Walla Walla",
        "StateID": "WA",
        "url": "wallawalla-wa"
    },
    {
        "State": "Washington",
        "City": "Mount Vernon",
        "StateID": "WA",
        "url": "mountvernon-wa"
    },
    {
        "State": "Washington",
        "City": "Oak Harbor",
        "StateID": "WA",
        "url": "oakharbor-wa"
    },
    {
        "State": "Washington",
        "City": "Bainbridge Island",
        "StateID": "WA",
        "url": "bainbridgeisland-wa"
    },
    {
        "State": "Washington",
        "City": "Moses Lake",
        "StateID": "WA",
        "url": "moseslake-wa"
    },
    {
        "State": "Washington",
        "City": "SeaTac",
        "StateID": "WA",
        "url": "seatac-wa"
    },
    {
        "State": "Washington",
        "City": "Pullman",
        "StateID": "WA",
        "url": "pullman-wa"
    },
    {
        "State": "Washington",
        "City": "Des Moines",
        "StateID": "WA",
        "url": "desmoines-wa"
    },
    {
        "State": "Washington",
        "City": "Lake Stevens",
        "StateID": "WA",
        "url": "lakestevens-wa"
    },
    {
        "State": "Washington",
        "City": "Arlington",
        "StateID": "WA",
        "url": "arlington-wa"
    },
    {
        "State": "Washington",
        "City": "Tumwater",
        "StateID": "WA",
        "url": "tumwater-wa"
    },
    {
        "State": "Washington",
        "City": "Covington",
        "StateID": "WA",
        "url": "covington-wa"
    },
    {
        "State": "Washington",
        "City": "Battle Ground",
        "StateID": "WA",
        "url": "battleground-wa"
    },
    {
        "State": "Washington",
        "City": "Camas",
        "StateID": "WA",
        "url": "camas-wa"
    },
    {
        "State": "Washington",
        "City": "Tumwater",
        "StateID": "WA",
        "url": "tumwater-wa"
    },
    {
        "State": "Washington",
        "City": "Mountlake Terrace",
        "StateID": "WA",
        "url": "mountlaketerrace-wa"
    },
    {
        "State": "Washington",
        "City": "Port Angeles",
        "StateID": "WA",
        "url": "portangeles-wa"
    },
    {
        "State": "Washington",
        "City": "Ellensburg",
        "StateID": "WA",
        "url": "ellensburg-wa"
    },
    {
        "State": "Washington",
        "City": "Sunnyside",
        "StateID": "WA",
        "url": "sunnyside-wa"
    },
    {
        "State": "Washington",
        "City": "Centralia",
        "StateID": "WA",
        "url": "centralia-wa"
    },
    {
        "State": "Washington",
        "City": "Anacortes",
        "StateID": "WA",
        "url": "anacortes-wa"
    },
    {
        "State": "Washington",
        "City": "Aberdeen",
        "StateID": "WA",
        "url": "aberdeen-wa"
    },
    {
        "State": "Washington",
        "City": "Washougal",
        "StateID": "WA",
        "url": "washougal-wa"
    },
    {
        "State": "Washington",
        "City": "Sedro-Woolley",
        "StateID": "WA",
        "url": "sedro-woolley-wa"
    },
    {
        "State": "Washington",
        "City": "Tukwila",
        "StateID": "WA",
        "url": "tukwila-wa"
    },
    {
        "State": "Washington",
        "City": "Kelso",
        "StateID": "WA",
        "url": "kelso-wa"
    },
    {
        "State": "Washington",
        "City": "Ferndale",
        "StateID": "WA",
        "url": "ferndale-wa"
    },
    {
        "State": "Washington",
        "City": "Mill Creek",
        "StateID": "WA",
        "url": "millcreek-wa"
    },
    {
        "State": "Washington",
        "City": "Port Orchard",
        "StateID": "WA",
        "url": "portorchard-wa"
    },
    {
        "State": "Washington",
        "City": "Cheney",
        "StateID": "WA",
        "url": "cheney-wa"
    },
    {
        "State": "Washington",
        "City": "Enumclaw",
        "StateID": "WA",
        "url": "enumclaw-wa"
    },
    {
        "State": "Washington",
        "City": "Monroe",
        "StateID": "WA",
        "url": "monroe-wa"
    },
    {
        "State": "Washington",
        "City": "East Wenatchee",
        "StateID": "WA",
        "url": "eastwenatchee-wa"
    },
    {
        "State": "Washington",
        "City": "West Richland",
        "StateID": "WA",
        "url": "westrichland-wa"
    },
    {
        "State": "Washington",
        "City": "Poulsbo",
        "StateID": "WA",
        "url": "poulsbo-wa"
    },
    {
        "State": "Washington",
        "City": "Bonney Lake",
        "StateID": "WA",
        "url": "bonneylake-wa"
    },
    {
        "State": "Washington",
        "City": "Black Diamond",
        "StateID": "WA",
        "url": "blackdiamond-wa"
    },
    {
        "State": "Washington",
        "City": "DuPont",
        "StateID": "WA",
        "url": "dupont-wa"
    },
    {
        "State": "Washington",
        "City": "Gig Harbor",
        "StateID": "WA",
        "url": "gigharbor-wa"
    },
    {
        "State": "Washington",
        "City": "Snoqualmie",
        "StateID": "WA",
        "url": "snoqualmie-wa"
    },
    {
        "State": "Washington",
        "City": "Lynden",
        "StateID": "WA",
        "url": "lynden-wa"
    },
    {
        "State": "Washington",
        "City": "Grandview",
        "StateID": "WA",
        "url": "grandview-wa"
    },
    {
        "State": "Washington",
        "City": "Lake Forest Park",
        "StateID": "WA",
        "url": "lakeforestpark-wa"
    },
    {
        "State": "Washington",
        "City": "Burlington",
        "StateID": "WA",
        "url": "burlington-wa"
    },
    {
        "State": "Washington",
        "City": "Orting",
        "StateID": "WA",
        "url": "orting-wa"
    },
    {
        "State": "Washington",
        "City": "North Bend",
        "StateID": "WA",
        "url": "northbend-wa"
    },
    {
        "State": "Washington",
        "City": "Clarkston",
        "StateID": "WA",
        "url": "clarkston-wa"
    },
    {
        "State": "Washington",
        "City": "Blaine",
        "StateID": "WA",
        "url": "blaine-wa"
    },
    {
        "State": "Washington",
        "City": "Leavenworth",
        "StateID": "WA",
        "url": "leavenworth-wa"
    },
    {
        "State": "Washington",
        "City": "Selah",
        "StateID": "WA",
        "url": "selah-wa"
    },
    {
        "State": "Washington",
        "City": "Connell",
        "StateID": "WA",
        "url": "connell-wa"
    },
    {
        "State": "Washington",
        "City": "Othello",
        "StateID": "WA",
        "url": "othello-wa"
    },
    {
        "State": "Washington",
        "City": "Colville",
        "StateID": "WA",
        "url": "colville-wa"
    },
    {
        "State": "Washington",
        "City": "Ephrata",
        "StateID": "WA",
        "url": "ephrata-wa"
    },
    {
        "State": "Washington",
        "City": "Port Townsend",
        "StateID": "WA",
        "url": "porttownsend-wa"
    },
    {
        "State": "Washington",
        "City": "Chehalis",
        "StateID": "WA",
        "url": "chehalis-wa"
    },
    {
        "State": "Washington",
        "City": "White Salmon",
        "StateID": "WA",
        "url": "whitesalmon-wa"
    },
    {
        "State": "Washington",
        "City": "Fife",
        "StateID": "WA",
        "url": "fife-wa"
    },
    {
        "State": "Washington",
        "City": "Liberty Lake",
        "StateID": "WA",
        "url": "libertylake-wa"
    },
    {
        "State": "Washington",
        "City": "Goldendale",
        "StateID": "WA",
        "url": "goldendale-wa"
    },
    {
        "State": "Washington",
        "City": "Hoquiam",
        "StateID": "WA",
        "url": "hoquiam-wa"
    },
    {
        "State": "Washington",
        "City": "Toppenish",
        "StateID": "WA",
        "url": "toppenish-wa"
    },
    {
        "State": "Washington",
        "City": "Zillah",
        "StateID": "WA",
        "url": "zillah-wa"
    },
    {
        "State": "Washington",
        "City": "Ocean Shores",
        "StateID": "WA",
        "url": "oceanshores-wa"
    },
    {
        "State": "Washington",
        "City": "Newport",
        "StateID": "WA",
        "url": "newport-wa"
    },
    {
        "State": "New Jersey",
        "City": "Newark",
        "StateID": "NJ",
        "url": "newark-nj"
    },
    {
        "State": "New Jersey",
        "City": "Jersey City",
        "StateID": "NJ",
        "url": "jerseycity-nj"
    },
    {
        "State": "New Jersey",
        "City": "Paterson",
        "StateID": "NJ",
        "url": "paterson-nj"
    },
    {
        "State": "New Jersey",
        "City": "Elizabeth",
        "StateID": "NJ",
        "url": "elizabeth-nj"
    },
    {
        "State": "New Jersey",
        "City": "Lakewood",
        "StateID": "NJ",
        "url": "lakewood-nj"
    },
    {
        "State": "New Jersey",
        "City": "Edison",
        "StateID": "NJ",
        "url": "edison-nj"
    },
    {
        "State": "New Jersey",
        "City": "Woodbridge",
        "StateID": "NJ",
        "url": "woodbridge-nj"
    },
    {
        "State": "New Jersey",
        "City": "Toms River",
        "StateID": "NJ",
        "url": "tomsriver-nj"
    },
    {
        "State": "New Jersey",
        "City": "Hamilton Township",
        "StateID": "NJ",
        "url": "hamiltontownship-nj"
    },
    {
        "State": "New Jersey",
        "City": "Trenton",
        "StateID": "NJ",
        "url": "trenton-nj"
    },
    {
        "State": "New Jersey",
        "City": "Clifton",
        "StateID": "NJ",
        "url": "clifton-nj"
    },
    {
        "State": "New Jersey",
        "City": "Camden",
        "StateID": "NJ",
        "url": "camden-nj"
    },
    {
        "State": "New Jersey",
        "City": "Passaic",
        "StateID": "NJ",
        "url": "passaic-nj"
    },
    {
        "State": "New Jersey",
        "City": "Union City",
        "StateID": "NJ",
        "url": "unioncity-nj"
    },
    {
        "State": "New Jersey",
        "City": "Bayonne",
        "StateID": "NJ",
        "url": "bayonne-nj"
    },
    {
        "State": "New Jersey",
        "City": "East Orange",
        "StateID": "NJ",
        "url": "eastorange-nj"
    },
    {
        "State": "New Jersey",
        "City": "Vineland",
        "StateID": "NJ",
        "url": "vineland-nj"
    },
    {
        "State": "New Jersey",
        "City": "North Bergen",
        "StateID": "NJ",
        "url": "northbergen-nj"
    },
    {
        "State": "New Jersey",
        "City": "New Brunswick",
        "StateID": "NJ",
        "url": "newbrunswick-nj"
    },
    {
        "State": "New Jersey",
        "City": "Hoboken",
        "StateID": "NJ",
        "url": "hoboken-nj"
    },
    {
        "State": "New Jersey",
        "City": "West New York",
        "StateID": "NJ",
        "url": "westnewyork-nj"
    },
    {
        "State": "New Jersey",
        "City": "Perth Amboy",
        "StateID": "NJ",
        "url": "perthamboy-nj"
    },
    {
        "State": "New Jersey",
        "City": "Plainfield",
        "StateID": "NJ",
        "url": "plainfield-nj"
    },
    {
        "State": "New Jersey",
        "City": "Bloomfield",
        "StateID": "NJ",
        "url": "bloomfield-nj"
    },
    {
        "State": "New Jersey",
        "City": "Linden",
        "StateID": "NJ",
        "url": "linden-nj"
    },
    {
        "State": "New Jersey",
        "City": "Hackensack",
        "StateID": "NJ",
        "url": "hackensack-nj"
    },
    {
        "State": "New Jersey",
        "City": "Sayreville",
        "StateID": "NJ",
        "url": "sayreville-nj"
    },
    {
        "State": "New Jersey",
        "City": "Kearny",
        "StateID": "NJ",
        "url": "kearny-nj"
    },
    {
        "State": "New Jersey",
        "City": "Atlantic City",
        "StateID": "NJ",
        "url": "atlanticcity-nj"
    },
    {
        "State": "New Jersey",
        "City": "East Brunswick",
        "StateID": "NJ",
        "url": "eastbrunswick-nj"
    },
    {
        "State": "New Jersey",
        "City": "North Brunswick",
        "StateID": "NJ",
        "url": "northbrunswick-nj"
    },
    {
        "State": "New Jersey",
        "City": "West Orange",
        "StateID": "NJ",
        "url": "westorange-nj"
    },
    {
        "State": "New Jersey",
        "City": "Englewood",
        "StateID": "NJ",
        "url": "englewood-nj"
    },
    {
        "State": "New Jersey",
        "City": "Garfield",
        "StateID": "NJ",
        "url": "garfield-nj"
    },
    {
        "State": "New Jersey",
        "City": "Teaneck",
        "StateID": "NJ",
        "url": "teaneck-nj"
    },
    {
        "State": "New Jersey",
        "City": "Fair Lawn",
        "StateID": "NJ",
        "url": "fairlawn-nj"
    },
    {
        "State": "New Jersey",
        "City": "Bergenfield",
        "StateID": "NJ",
        "url": "bergenfield-nj"
    },
    {
        "State": "New Jersey",
        "City": "Rahway",
        "StateID": "NJ",
        "url": "rahway-nj"
    },
    {
        "State": "New Jersey",
        "City": "Ridgewood",
        "StateID": "NJ",
        "url": "ridgewood-nj"
    },
    {
        "State": "New Jersey",
        "City": "Fort Lee",
        "StateID": "NJ",
        "url": "fortlee-nj"
    },
    {
        "State": "New Jersey",
        "City": "Paramus",
        "StateID": "NJ",
        "url": "paramus-nj"
    },
    {
        "State": "New Jersey",
        "City": "Hoboken",
        "StateID": "NJ",
        "url": "hoboken-nj"
    },
    {
        "State": "New Jersey",
        "City": "Morristown",
        "StateID": "NJ",
        "url": "morristown-nj"
    },
    {
        "State": "New Jersey",
        "City": "Mahwah",
        "StateID": "NJ",
        "url": "mahwah-nj"
    },
    {
        "State": "New Jersey",
        "City": "South Brunswick",
        "StateID": "NJ",
        "url": "southbrunswick-nj"
    },
    {
        "State": "New Jersey",
        "City": "Millville",
        "StateID": "NJ",
        "url": "millville-nj"
    },
    {
        "State": "New Jersey",
        "City": "Carteret",
        "StateID": "NJ",
        "url": "carteret-nj"
    },
    {
        "State": "New Jersey",
        "City": "Roselle",
        "StateID": "NJ",
        "url": "roselle-nj"
    },
    {
        "State": "New Jersey",
        "City": "Pennsauken",
        "StateID": "NJ",
        "url": "pennsauken-nj"
    },
    {
        "State": "New Jersey",
        "City": "Belleville",
        "StateID": "NJ",
        "url": "belleville-nj"
    },
    {
        "State": "New Jersey",
        "City": "South Plainfield",
        "StateID": "NJ",
        "url": "southplainfield-nj"
    },
    {
        "State": "New Jersey",
        "City": "Randolph",
        "StateID": "NJ",
        "url": "randolph-nj"
    },
    {
        "State": "New Jersey",
        "City": "Ewing",
        "StateID": "NJ",
        "url": "ewing-nj"
    },
    {
        "State": "New Jersey",
        "City": "Hillside",
        "StateID": "NJ",
        "url": "hillside-nj"
    },
    {
        "State": "New Jersey",
        "City": "Maplewood",
        "StateID": "NJ",
        "url": "maplewood-nj"
    },
    {
        "State": "New Jersey",
        "City": "Lodi",
        "StateID": "NJ",
        "url": "lodi-nj"
    },
    {
        "State": "New Jersey",
        "City": "Secaucus",
        "StateID": "NJ",
        "url": "secaucus-nj"
    },
    {
        "State": "New Jersey",
        "City": "Long Branch",
        "StateID": "NJ",
        "url": "longbranch-nj"
    },
    {
        "State": "New Jersey",
        "City": "Phillipsburg",
        "StateID": "NJ",
        "url": "phillipsburg-nj"
    },
    {
        "State": "New Jersey",
        "City": "Raritan Township",
        "StateID": "NJ",
        "url": "raritantownship-nj"
    },
    {
        "State": "New Jersey",
        "City": "Westfield",
        "StateID": "NJ",
        "url": "westfield-nj"
    },
    {
        "State": "New Jersey",
        "City": "Montclair",
        "StateID": "NJ",
        "url": "montclair-nj"
    },
    {
        "State": "New Jersey",
        "City": "Summit",
        "StateID": "NJ",
        "url": "summit-nj"
    },
    {
        "State": "New Jersey",
        "City": "Sparta",
        "StateID": "NJ",
        "url": "sparta-nj"
    },
    {
        "State": "New Jersey",
        "City": "West Milford",
        "StateID": "NJ",
        "url": "westmilford-nj"
    },
    {
        "State": "New Jersey",
        "City": "Nutley",
        "StateID": "NJ",
        "url": "nutley-nj"
    },
    {
        "State": "New Jersey",
        "City": "Palisades Park",
        "StateID": "NJ",
        "url": "palisadespark-nj"
    },
    {
        "State": "New Jersey",
        "City": "Middletown",
        "StateID": "NJ",
        "url": "middletown-nj"
    },
    {
        "State": "New Jersey",
        "City": "Ocean Township",
        "StateID": "NJ",
        "url": "oceantownship-nj"
    },
    {
        "State": "New Jersey",
        "City": "Marlboro",
        "StateID": "NJ",
        "url": "marlboro-nj"
    },
    {
        "State": "New Jersey",
        "City": "Howell",
        "StateID": "NJ",
        "url": "howell-nj"
    },
    {
        "State": "New Jersey",
        "City": "Vernon Township",
        "StateID": "NJ",
        "url": "vernontownship-nj"
    },
    {
        "State": "New Jersey",
        "City": "Galloway Township",
        "StateID": "NJ",
        "url": "gallowaytownship-nj"
    },
    {
        "State": "New Jersey",
        "City": "Mount Laurel",
        "StateID": "NJ",
        "url": "mountlaurel-nj"
    },
    {
        "State": "New Jersey",
        "City": "Bridgewater",
        "StateID": "NJ",
        "url": "bridgewater-nj"
    },
    {
        "State": "New Jersey",
        "City": "Monroe Township",
        "StateID": "NJ",
        "url": "monroetownship-nj"
    },
    {
        "State": "New Jersey",
        "City": "Willingboro",
        "StateID": "NJ",
        "url": "willingboro-nj"
    },
    {
        "State": "New Jersey",
        "City": "Washington Township",
        "StateID": "NJ",
        "url": "washingtontownship-nj"
    },
    {
        "State": "New Jersey",
        "City": "Tinton Falls",
        "StateID": "NJ",
        "url": "tintonfalls-nj"
    },
    {
        "State": "New Jersey",
        "City": "Hazlet",
        "StateID": "NJ",
        "url": "hazlet-nj"
    },
    {
        "State": "New Jersey",
        "City": "Holmdel",
        "StateID": "NJ",
        "url": "holmdel-nj"
    },
    {
        "State": "New Jersey",
        "City": "Hillsborough",
        "StateID": "NJ",
        "url": "hillsborough-nj"
    },
    {
        "State": "New Jersey",
        "City": "Scotch Plains",
        "StateID": "NJ",
        "url": "scotchplains-nj"
    },
    {
        "State": "New Jersey",
        "City": "Moorestown",
        "StateID": "NJ",
        "url": "moorestown-nj"
    },
    {
        "State": "New Jersey",
        "City": "Evesham",
        "StateID": "NJ",
        "url": "evesham-nj"
    },
    {
        "State": "New Jersey",
        "City": "Rockaway Township",
        "StateID": "NJ",
        "url": "rockawaytownship-nj"
    },
    {
        "State": "New Jersey",
        "City": "Madison",
        "StateID": "NJ",
        "url": "madison-nj"
    },
    {
        "State": "New Jersey",
        "City": "Livingston",
        "StateID": "NJ",
        "url": "livingston-nj"
    },
    {
        "State": "New Jersey",
        "City": "Parsippany",
        "StateID": "NJ",
        "url": "parsippany-nj"
    },
    {
        "State": "New Jersey",
        "City": "Ridgefield Park",
        "StateID": "NJ",
        "url": "ridgefieldpark-nj"
    },
    {
        "State": "New Jersey",
        "City": "Fairview",
        "StateID": "NJ",
        "url": "fairview-nj"
    },
    {
        "State": "New Jersey",
        "City": "Weehawken",
        "StateID": "NJ",
        "url": "weehawken-nj"
    },
    {
        "State": "New Jersey",
        "City": "West Deptford",
        "StateID": "NJ",
        "url": "westdeptford-nj"
    },
    {
        "State": "New Jersey",
        "City": "Haddonfield",
        "StateID": "NJ",
        "url": "haddonfield-nj"
    },
    {
        "State": "New Jersey",
        "City": "Little Falls",
        "StateID": "NJ",
        "url": "littlefalls-nj"
    },
    {
        "State": "New Jersey",
        "City": "Pompton Lakes",
        "StateID": "NJ",
        "url": "pomptonlakes-nj"
    },
    {
        "State": "New Jersey",
        "City": "Bogota",
        "StateID": "NJ",
        "url": "bogota-nj"
    },
    {
        "State": "New Jersey",
        "City": "Leonia",
        "StateID": "NJ",
        "url": "leonia-nj"
    },
    {
        "State": "New Jersey",
        "City": "Hawthorne",
        "StateID": "NJ",
        "url": "hawthorne-nj"
    },
    {
        "State": "New Jersey",
        "City": "Glen Rock",
        "StateID": "NJ",
        "url": "glenrock-nj"
    }
]
export default CitiesData;
